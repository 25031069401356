<template>
  <ul class="list-group">
    <li v-show="visibility.failures"
        v-for="(failure, key) in getNotesBySeverity(entity, Severity.FAILURE)"
        :key="`fail-${key}`" class="list-group-item list-group-item-danger" >
      <strong>{{ failure.message }}</strong>
    </li>
    <li v-show="visibility.warnings"
        v-for="(warning, key) in getNotesBySeverity(entity, Severity.WARNING)"
        :key="`warn-${key}`" class="list-group-item list-group-item-warning">
      <strong>{{ warning.message }}</strong>
    </li>
    <li v-show="visibility.tips"
        v-for="(tip, key) in getNotesBySeverity(entity, Severity.TIP)"
        :key="`tip-${key}`" class="list-group-item list-group-item-info">
      <strong>{{ tip.message }}</strong>
    </li>
    <li v-show="visibility.info"
        v-for="(info, key) in getNotesBySeverity(entity, Severity.INFO)"
        :key="`info-${key}`" class="list-group-item list-group-item-secondary">
      <strong>{{ info.message }}</strong>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['entity', 'visibility'],
};
</script>
