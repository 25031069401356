/**
 * A severity class of a note.
 */
export default class Severity {
}

Severity.INFO = 'INFO';
Severity.TIP = 'TIP';
Severity.WARNING = 'WARNING';
Severity.FAILURE = 'FAILURE';
