import ProgressStatus from './ProgressStatus.js';

export default class ProgressPart {
  constructor(name, status = ProgressStatus.PENDING, weight = null) {
    this.name = name;
    this.status = status;
    this.weight = weight;
  }

  getContributingWeight() {
    return this.status !== this.isDone() ? this.weight : 0;
  }

  isDone() {
    return this.status !== ProgressStatus.PENDING;
  }
}
