import Worker from './Worker.js';
import User from './User.js';
import Token from './Token.js';
import Assignment from './Assignment.js';
import Page from './Page.js';
import Submission from './Submission.js';
import Course from './Course.js';
import TestSuiteResults from './TestSuiteResults.js';
import Workers from './workers.js';

/**
 * An AuTA API client.
 */
export default class Client {
  /**
   * Creates a new AuTA API client.
   *
   * @param config the client configuration
   */
  constructor(config) {
    this.config = config;
    this.http = config.http;

    this.workers = new Workers(this);
  }

  /**
   * Requests details about the user currently logged in.
   *
   * Usually this call is not necessary since the values are cached. The cache is available as {@link #user}.
   *
   * @param noCache if {@code true}, requests fresh information instead of returning the cached information
   *
   * @returns {Promise<User>}
   */
  async getUserInfo(noCache) {
    if (this.user && !noCache) return this.user;

    const res = await this.http.get('/api/v1/user');
    const { data } = res;
    return this.user = new User(data.name, data.enabled, data.authorities);
  }

  /**
   * Connects to the AuTA API.
   *
   * If this method succeeds, then the client is usable for further API interaction.
   *
   * @returns {Promise<void>}
   */
  async connect() {
    await this.getUserInfo();
  }

  /**
   * Logs the user in with a username and password.
   *
   * @param username the username
   * @param password the password
   *
   * @returns {Promise<Token>}
   */
  async login(username, password) {
    if (this.config.authProvider.getToken(true).token) {
      await this.logout();
    }

    const { data } = await this.http.post('/api/v1/user/login', { username, password }, true);
    const newToken = new Token(data.token, data.expiry);
    await this.config.authProvider.setToken(newToken);
    return newToken;
  }

  /**
   * Gets all users on a certain page.
   *
   * @param page {number} the page number
   * @param pageSize {number} the page size
   * @returns {Promise<Page>}
   */
  async getUsers(page, pageSize) {
    const { data } = await this.http.get(`/api/v1/users?page=${page}&size=${pageSize}`);
    return new Page(
      data.users.map(s => new User(s.username, s.authorities, s.enabled)),
      data.page, pageSize, data.numPages,
    );
  }

  /**
   * Registers a user with a username, password and authority.
   *
   * @param username {string} the username
   * @param password {string} the password
   * @param authority {string} the user's authority (e.g. ROLE_TA)
   * @returns {Promise<void>}
   */
  async registerUser(username, password, authority) {
    await this.http.post('/api/v1/user/register', { username, password, authority });
  }

  /**
   * Updates a user's authority and/or enabled status.
   *
   * @param {string} username the username
   * @param {string} authority the authority (e.g. ROLE_TA)
   * @param {boolean} enabled whether the user is enabeld or not
   * @returns {Promise<void>}
   */
  async updateUser(username, authority, enabled) {
    await this.http.put('/api/v1/user', { username, authority, enabled });
  }

  /**
   * Updates a user's password.
   *
   * @param username {string} the username
   * @param oldPassword {string} the old password
   * @param password {string} the new password
   * @returns {Promise<void>}
   */
  async updatePassword(username, oldPassword, password) {
    await this.http.put('/api/v1/user/password', { username, password, oldPassword });
  }

  /**
   * Logs the user out, invalidating their token.
   *
   * @returns {Promise<void>}
   */
  async logout() {
    const token = await this.config.authProvider.getToken(true);
    if (!token.token) return;

    try {
      await this.http.delete('/api/v1/user/logout');
    } catch (ex) {
      // Ignore
    }

    await this.config.authProvider.setToken(new Token(undefined, undefined));
  }

  /**
   * Returns the list of workers currently connected to the core.
   *
   * @returns {Promise<Array<Worker>>}
   */
  async getWorkers() {
    const res = await this.http.get('/api/v1/workers');
    return res.data.workers.map(w => Worker.parse(w, this));
  }

  /**
   * Gets the list of courses available to the current user.
   *
   * @returns {Promise<Course>}
   */
  async getCourses() {
    const res = await this.http.get('/api/v1/course');
    return res.data.courses.map(c => new Course(c.id, c.name, c.courseCode, c.year, c.taSet, c.instructorSet,
      c.assignmentIds, this));
  }

  /**
   * Gets a course with a specific id.
   *
   * @returns {Promise<Course>} or undefined if the course does not exist.
   */
  async getCourse(id) {
    const courses = await this.getCourses();
    return courses.find(c => c.id === id);
  }

  /**
   * Updates a course.
   *
   * @param course to update
   *
   * @returns {Promise<void>}
   */
  async updateCourse(course) {
    await this.http.put(`/api/v1/course/${course.id}`, course.serialize());
  }

  /**
   * Adds a user to a course.
   *
   * @param course to update
   * @param username the username to add
   * @param role the role to add
   *
   * @returns {Promise<void>}
   */
  async addUserToCourse(course, username, role) {
    await this.http.put(`/api/v1/course/${course.id}/user`, { role, username });
  }

  /**
   * Removes a user from a course.
   *
   * @param course to update
   * @param username the username to remove
   * @param role the role to remove
   *
   * @returns {Promise<void>}
   */
  async removeUserFromCourse(course, username, role) {
    await this.http.delete(`/api/v1/course/${course.id}/user/${role}/${username}`);
  }

  /**
   * Adds an assignment to a course.
   *
   * @param course to update
   * @param aid the id of the assignment to add to the course
   *
   * @returns {Promise<void>}
   */
  async addAssignmentToCourse(course, aid) {
    await this.http.put(`/api/v1/course/${course.id}/assignment`, { aid });
  }

  /**
   * Removes an assignment from a course.
   *
   * @param course to update
   * @param aid the id of the assignment to remove from the course
   *
   * @returns {Promise<void>}
   */
  async removeAssignmentFromCourse(course, aid) {
    await this.http.delete(`/api/v1/course/${course.id}/assignment/${aid}`);
  }

  /**
   * Deletes a course.
   *
   * @param cid the ID of the course to delete
   *
   * @returns {Promise<void>}
   */
  async deleteCourse(cid) {
    await this.http.delete(`/api/v1/course/${cid}`);
  }

  /**
   * Uploads a new course.
   *
   * The course instance is updated with the new ID.
   *
   * @param course the new assignment to create
   *
   * @returns {Promise<string>} the ID of the new assignment
   */
  async addCourse(course) {
    const { data } = await this.http.post('/api/v1/course', course.serialize());
    course.id = data.id;
    return data.id;
  }

  /**
   * Creates a new (local) course.
   *
   * The course is not yet uploaded to the server; therefore the ID of the course is undefined until
   * {@link #addCourse}  or {@link Course#create()} is called on the course.
   *
   * @param name the name of the course
   * @param courseCode the code of the course
   * @param year the year of the course
   *
   * @returns {Course}
   */
  createNewCourse(name, courseCode, year) {
    return Course.createNew(name, courseCode, year, this);
  }

  /**
   * Returns the list of assignments available to the current user.
   *
   * @returns {Promise<Array<Assignment>>}
   */
  async getAssignments() {
    const res = await this.http.get('/api/v1/assignment');
    return res.data.assignments.map(a => Assignment.parse(a, this));
  }

  /**
   * Looks up assignment info by the assignment's ID.
   *
   * @param aid the assignment id
   *
   * @returns {Promise<Assignment>}
   */
  async getAssignment(aid) {
    const { data } = await this.http.get(`/api/v1/assignment/${aid}`);
    return Assignment.parse(data, this);
  }

  /**
   * Returns a page of submissions made for the given assignment.
   *
   * The object has format
   * {
   *  page: {number},
   *  submissions: object<
   *      {
   *          identity: null | object<{submissionId: {String}, identifier: {String}}>,
   *          name: {String},
   *          id: {String}
   *      }>[]
   * }
   *
   * @param aid the assignment id
   * @param page the page number
   * @param pageSize the number of submissions per page
   *
   * @returns {Promise<Page>}
   */
  async getSubmissions(aid, page, pageSize) {
    const { data } = await this.http.get(`/api/v1/assignment/${aid}/submission?page=${page}&size=${pageSize}`);
    return new Page(
      data.submissions.map((s) => {
        const submission = Submission.parse(s, this);
        submission.aid = aid;
        return submission;
      }),
      data.page, pageSize, data.numPages,
    );
  }

  /**
   * Returns a submission.
   *
   * @param aid the assignment id
   * @param sid the submission id
   *
   * @returns {Promise<Submission>}
   */
  async getSubmission(aid, sid) {
    const { data } = await this.http.get(`/api/v1/assignment/${aid}/submission/${sid}`);
    return Submission.parse(data, this);
  }

  /**
   * Returns the verdict on a submission.
   *
   * @param aid the assignment id
   * @param sid the submission id
   * @returns {Promise<*>}
   */
  async getVerdict(aid, sid) {
    const { data } = await this.http.get(`/api/v1/assignment/${aid}/submission/${sid}/verdict`);
    return data.verdict;
  }

  /**
   * Returns the metrics supported by the server and its workers.
   *
   * @returns {Promise<*>} the metrics
   */
  async getMetrics() {
    const { data } = await this.http.get('/api/v1/worker/metrics');
    return data.metrics;
  }

  /**
   * Returns suggested values for metric script unit tests.
   *
   * @returns {Promise<*>} the fixtures
   */
  async getMetricTestFixtures() {
    const { data } = await this.http.get('/api/v1/worker/metrics/test-fixtures');
    return data.fixtures;
  }

  /**
   * Generates a new worker token.
   *
   * @returns {Promise<string>}
   */
  async getWorkerToken() {
    const { data } = await this.http.get('/api/v1/worker/token');
    return data.token;
  }

  /**
   * Updates an assignment.
   *
   * @param assignment the assignment to update
   *
   * @returns {Promise<void>}
   */
  async updateAssignment(assignment) {
    await this.http.put(`/api/v1/assignment/${assignment.id}`, assignment.serialize());
  }

  /**
   * Uploads a new assignment.
   *
   * The assignment instance is updated with the new ID.
   *
   * @param assignment the new assignment to create
   *
   * @returns {Promise<string>} the ID of the new assignment
   */
  async addAssignment(assignment) {
    const { data } = await this.http.post('/api/v1/assignment', assignment.serialize());
    assignment.id = data.id;
    return data.id;
  }

  /**
   * Deletes an assignment.
   *
   * @param aid the ID of the assignment to delete
   *
   * @returns {Promise<void>}
   */
  async deleteAssignment(aid) {
    await this.http.delete(`/api/v1/assignment/${aid}`);
  }

  /**
   * Creates a new (local) assignment.
   *
   * The assignment is not yet uploaded to the server; therefore the ID of the assignment is undefined until
   * {@link #addAssignment}  or {@link Assignment#create()} is called on the assignment.
   *
   * @param name the name of the assignment
   * @param options? the options of the assignment, defaults to an empty object
   *
   * @returns {Assignment}
   */
  createNewAssignment(name, options = {}) {
    return Assignment.createNew(name, options, this);
  }

  /**
   * Retrieves the settings from the core.
   *
   * @returns {Promise<{}>} the settings
   */
  async getSettings() {
    const { data } = await this.http.get('/api/v1/settings');
    return data.settings;
  }

  /**
   * Sets the settings for the core.
   *
   * @param settings the settings
   *
   * @returns {Promise<void>}
   */
  async setSettings(settings) {
    await this.http.put('/api/v1/settings', settings);
  }

  /**
   * Uploads a file as a new submission.
   *
   * @param aid the ID of the assignment to upload to
   * @param file the file to upload
   *
   * @returns {Promise<Submission>}
   */
  async upload(aid, file) {
    const { data } = await this.http.post(
      `/api/v1/assignment/${aid}/submission/direct-upload`, file, false, 'multipart/form-data',
    );
    return new Submission(aid, data.id, data.id, null, this);
  }

  /**
   * Retrieves the core's logs from its ring buffer.
   *
   * @returns {Promise<Array<String>>}
   */
  async getCoreLogs() {
    const { data } = await this.http.get('/api/v1/logs/core');
    return data.logs;
  }

  /**
   * Gets the chart data for a specific submission.
   *
   * @param submission {Submission} the submission
   * @param metric {Metric | {name: {String}}} the metric that is requested
   * @param entityLevel {String} the entity level
   */
  async getBenchmarkingChartData(submission, metric, entityLevel) {
    const { data } = await this.http.post(
      `/api/v1/assignment/${submission.aid}/submission/${submission.id}/benchmark/chartdata`,
      { metricNameString: metric.name, entityLevelString: entityLevel },
    );
    return data;
  }

  /**
   * Gets the badge for a submission.
   *
   * Expects a response from the server that includes the following field {badge: {string}}, which should be an svg.
   * @param aid {string} the assignment id
   * @param sid {string} the submission id
   * @returns {Promise<string>} the badge as an svg
   */
  async getBadge(aid, sid) {
    const { data } = await this.http.get(`/api/v1/assignment/${aid}/submission/${sid}/benchmark/score`);
    return data.badge;
  }

  /**
   * Tests a script.
   *
   * @param testSuite the script test suite
   *
   * @returns {Promise<TestSuiteResults>} the test suite results
   */
  async testScript(testSuite) {
    const { data } = await this.http.post('/api/v1/script/test/passing-script', testSuite);
    return TestSuiteResults.parse(data);
  }
}
