<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3>API Authentication</h3>
        <form class="form-group row">
          <label for="authdb-path" class="col-md-3 col-form-label">
            Database location
          </label>
          <div class="col-md-9">
            <input type="text" id="authdb-path" class="form-control"
                   v-model="$parent.settings['authentication database path']"
            >
          </div>
        </form>
        <form class="form-group row">
          <label for="bcrypt-work-factor" class="col-md-3 col-form-label">
            Bcrypt work factor
          </label>
          <div class="col-md-9 input-group">
            <input type="number" id="bcrypt-work-factor" class="form-control"
                   min="1" max="16"
                   v-model="$parent.settings['bcrypt work factor']"
            >
            <div class="input-group-prepend">
              <span class="input-group-text"><sup>2</sup>log rounds</span>
            </div>
          </div>
        </form>
        <form class="form-group row">
          <label for="token-length" class="col-md-3 col-form-label">
            Token length
          </label>
          <div class="col-md-9 input-group">
            <input type="number" id="token-length" class="form-control"
                   min="64" max="65536"
                   v-model="$parent.settings['token length']"
            >
            <div class="input-group-append">
              <span class="input-group-text">bits</span>
            </div>
          </div>
        </form>
        <form class="form-group row">
          <label for="token-management-interval" class="col-md-3 col-form-label">
            Remove old tokens every
          </label>
          <div class="col-md-9 input-group">
            <input type="number" id="token-management-interval" class="form-control"
                   min="0"
                   v-model="$parent.settings['token management interval']"
            >
            <select class="form-control"
                    v-model="$parent.settings['token management interval unit']">
              <option v-for="unit in timeUnits" :key="`tmi-${unit}`"
                      :value="unit"
              >
                {{ unit.toLowerCase() }}
              </option>
            </select>
          </div>
        </form>
        <form class="form-group row" id="aao-form">
          <label for="allowed-api-origins" class="col-md-3 col-form-label">
            Allowed origins (CORS only)
          </label>
          <div class="col-md-9 input-group">
            <select multiple id="allowed-api-origins" class="form-control"
                    v-model="selectedRemotes"
            >
              <option v-for="remote in $parent.settings['allowed API origins']"
                      :key="`aao-${remote}`"
              >
                {{ remote }}
              </option>
            </select>
            <div class="input-group-append">
              <button class="btn btn-outline-danger" @click.prevent="delOrigins">
                <Fa ico="trash-alt" />
              </button>
            </div>
          </div>
        </form>
        <form class="form-group row justify-content-end" ref="newOrigin">
          <div class="col-md-9 input-group">
            <input type="text" class="form-control" v-model="newOrigin"
                   pattern="https?://[^/]+">
            <div class="input-group-append">
              <button class="btn btn-outline-success" @click.prevent="addOrigin">
                <Fa pfx="fas" ico="plus" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3>Assurance</h3>
        <form class="form-group row">
          <label for="security-check-interval" class="col-md-3 col-form-label">
            Perform security checks every
          </label>
          <div class="col-md-9 input-group">
            <input type="number" id="security-check-interval" class="form-control"
                   min="0"
                   v-model="$parent.settings['security check interval']"
             >
            <select class="form-control"
                    v-model="$parent.settings['security check interval unit']">
              <option v-for="unit in timeUnits" :key="`sci-${unit}`"
                      :value="unit"
              >
                {{ unit.toLowerCase() }}
              </option>
            </select>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3>Worker communication</h3>
        <form class="form-group row">
          <label for="encryption-key-length" class="col-md-3 col-form-label">
            Encryption key length
          </label>
          <div class="col-md-9 input-group">
            <select class="form-control" id="encryption-key-length"
                    v-model="$parent.settings['encryption key length']">
              <option value="128">128</option>
              <option value="256">256</option>
            </select>
            <div class="input-group-append">
              <span class="input-group-text">bits</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedRemotes: [],
      newOrigin: '',
    };
  },
  methods: {
    delOrigins() {
      this.$parent.settings['allowed API origins'] = this.$parent.settings['allowed API origins']
        .filter(o => this.selectedRemotes.indexOf(o) === -1);
    },
    addOrigin() {
      if (this.$refs.newOrigin.reportValidity()) {
        this.$parent.settings['allowed API origins'].push(this.newOrigin);
      }
    },
  },
};
</script>

<style scoped lang="less">
#aao-form {
  margin-bottom: 0;
}
</style>
