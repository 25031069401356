import TestResults from './TestResults.js';

/**
 * The results of an entire test suite.
 */
export default class TestSuiteResults {
  /**
   * Parses a test suite results collection from an object.
   *
   * @param obj? the object to parse the results from
   *
   * @returns {TestSuiteResults|null}
   */
  static parse(obj) {
    if (!obj) return null;

    return new TestSuiteResults(obj.compiles, obj.results.map(TestResults.parse));
  }

  /**
   * Creates a new test suite results collection.
   *
   * @param compiles true if compilation succeeds, an error string otherwise
   * @param results the list of results for the individual unit tests
   */
  constructor(compiles, results) {
    this.compiles = compiles;
    this.results = results;
  }
}
