/**
 * A course.
 */
export default class Course {
  /**
   * Creates a new course for uploading to the core.
   *
   * @param name the name of the new course
   * @param courseCode the courseCode
   * @param year the year the course starts in
   * @param $client the client instance the course is bound to
   *
   * @returns {Course}
   */
  static createNew(name, courseCode, year, $client) {
    return new Course(undefined, name, courseCode, year, [], [], [], $client);
  }

  /**
   * Creates a new course.
   *
   * @param id the course's identifier
   * @param name the name of the new course
   * @param courseCode the courseCode
   * @param year the year the course starts in
   * @param taSet the list of TA usernames
   * @param instructorSet the list of instructor usernames
   * @param assignmentIds the list of assignment ids.
   * @param $client the client instance that requested the course
   */
  constructor(id, name, courseCode, year, taSet, instructorSet, assignmentIds, $client) {
    this.id = id;
    this.name = name;
    this.courseCode = courseCode;
    this.year = year;
    this.taSet = taSet;
    this.instructorSet = instructorSet;
    this.assignmentIds = assignmentIds;
    this.$client = $client;
  }

  /**
   * Updates this course on the core.
   *
   * @returns {Promise<void>}
   */
  async update() {
    await this.$client.updateCourse(this);
  }

  /**
   * Creates this course on the core.
   *
   * The course is immediately updated with the new identifier.
   *
   * @returns {Promise<string>} the new ID
   */
  async create() {
    this.id = await this.$client.addCourse(this);
    return this.id;
  }

  /**
   * Deletes this course on the core.
   *
   * @returns {Promise<void>}
   */
  async delete() {
    await this.$client.deleteCourse(this.id);
  }

  /**
   * Adds a TA to this course.
   *
   * @param username the username to add
   * @returns {Promise<void>}
   */
  async addTA(username) {
    const ROLE = 'ROLE_TA';
    await this.$client.addUserToCourse(this, username, ROLE);
  }

  /**
   * Removes a TA from this course.
   *
   * @param username the username to remove
   * @returns {Promise<void>}
   */
  async removeTA(username) {
    const ROLE = 'ROLE_TA';
    await this.$client.removeUserFromCourse(this, username, ROLE);
  }

  /**
   * Adds a teacher to this course.
   *
   * @param username the username to add
   * @returns {Promise<void>}
   */
  async addTeacher(username) {
    const ROLE = 'ROLE_TEACHER';
    await this.$client.addUserToCourse(this, username, ROLE);
  }

  /**
   * Removes a teacher from this course.
   *
   * @param username the username to remove
   * @returns {Promise<void>}
   */
  async removeTeacher(username) {
    const ROLE = 'ROLE_TEACHER';
    await this.$client.removeUserFromCourse(this, username, ROLE);
  }

  /**
   * Serializes the course into an object that can be sent to the core.
   *
   * @returns {{name: *, courseCode: *, year: *}} the serialized course
   */
  serialize() {
    return { name: this.name, courseCode: this.courseCode, year: this.year };
  }
}
