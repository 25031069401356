import Metric from './Metric.js';
import Report from './Report.js';

/**
 * A submission entity.
 */
export default class Entity {
  /**
   * Parses an entity from an object.
   *
   * @param obj? the object to parse the entity from
   * @param parent? the parent of the object to parse
   *
   * @returns {Entity|null}
   */
  static parse(obj, parent) {
    if (!obj) return null;

    const entity = new Entity(parent, obj.name, obj.testEntity, obj.level, [], [], []);

    entity.children = obj.children.map(c => Entity.parse(c, entity));
    entity.metrics = obj.metrics.map(Metric.parse);
    entity.reports = obj.reports.map(r => Report.parse(r, obj));

    return entity;
  }

  /**
   * Creates a new entity.
   *
   * @param parent? the parent entity
   * @param name the name of the entity
   * @param testEntity iff true, this entity belongs to the test suite of the submission
   * @param level the entity level
   * @param children the children of this entity
   * @param metrics the metric results for this entity
   * @param reports the reports for this entity
   */
  constructor(parent, name, testEntity, level, children, metrics, reports) {
    this.parent = parent;
    this.name = name;
    this.testEntity = testEntity;
    this.level = level;
    this.children = children;
    this.metrics = metrics;
    this.reports = reports;
  }

  /**
   * Returns a generator iterating over all children and their descendants.
   *
   * @returns {IterableIterator<Entity|IterableIterator<*|any>>}
   */
  * childGenerator() {
    yield this;

    for (const child of this.children) {
      yield* child.childGenerator();
    }
  }

  /**
   * Returns the full name of the entity, separated by slashes.
   *
   * @returns {string|*}
   */
  getFullName() {
    if (!this.parent) {
      return this.name;
    }

    return `${this.parent.getFullName()}/${this.name}`;
  }

  /**
   * Returns the entity as a string in the following format:
   *
   * LEVEL:[NAME]METRICS(CHILDREN)
   *
   * @returns {string}
   */
  toString() {
    return `${this.level}:[${this.name}]${this.metrics}(${this.children})`;
  }
}
