<template>
  <div v-if="loaded">
    <div class="row">
      <div class="col-12" id="breadcrumbs">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'assignments' }">Assignments</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'assignment', params: { aid: $route.params.aid } }">
              {{ assignment.name }}
            </router-link>
          </li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col-10">
        <button @click="showInfo = !showInfo"
                :class="['btn', 'btn-default', (showInfo) ? 'active' : '']">
          Show metrics
        </button>
        <button @click="showWarnings = !showWarnings"
                :class="['btn', 'btn-default', (showWarnings) ? 'active' : '']">
          Show warnings
        </button>
        <button @click="showFailures = !showFailures"
                :class="['btn', 'btn-default', (showFailures) ? 'active' : '']">
          Show failures
        </button>
        <button @click="showTips = !showTips"
                :class="['btn', 'btn-default', (showTips) ? 'active' : '']">
          Show tips
        </button>
        <button @click="showEmpty = !showEmpty"
                :class="['btn', 'btn-default', (showEmpty) ? 'active' : '']">
          Show empty
        </button>
      </div>
      <div class="col-2 text-right">
        <button @click="showAllToggle()"
                :class="['btn', 'btn-default', (showAll) ? 'active' : '']">
          Show all
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 order-md-last aside">
        <div class="status-card">
          <video loop autoplay v-if="verdict === 'pending'" class="status-image"
               src="/img/auta-faster.mp4" type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                 alt="Verdict"></video>
          <img v-else class="status-image"
               :src="`/img/auta-${verdict}-160x160.png`"
               alt="Verdict" />
          <span class="status-line">{{ verdict }}</span>
          <span class="sub-status-line" v-if="submission.canceled">CANCELED</span>
          <span class="m-auto" v-if="badge" ref="badge" id="badge" v-html="badge"></span>
        </div>
        <div>
          <dl>
            <dt>Submitted</dt>
            <dd>{{ formatSubmittedDate() }}</dd>
            <dt>Enqueued</dt>
            <dd>For {{ formatEnqueued() }}</dd>
            <dt>Analyzed</dt>
            <dd>For {{ formatAnalyzed() }}</dd>
            <dt>Processed</dt>
            <dd>{{ formatProcessed() }}</dd>
          </dl>
        </div>
        <div>
            <router-link class="btn btn-primary btn-block"
              :to="{ name: 'benchmarking', params: $route.params }">
              Benchmarking
            </router-link>
        </div>
        <div class="row">
          <h4>Legend</h4>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="test-legend"></div>
          </div>
          <div class="col-md-10">
            Test
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="source-legend"></div>
          </div>
          <div class="col-md-10">
            Source
          </div>
        </div>
        <div v-if="assignment.umlFormatterSrc && submission.uml">
          <!-- Output UML somewhere -->
          <h5>UML</h5>
          <img class="uml-preview" src="/img/placeholder-160x160.png" @click="showUml" />
        </div>
      </div>
      <div class="col-md-9">
        <h2 class="submission-name">{{ submission.name }}</h2>
        <div v-if="submission.exception">
          <h3>The submission caused an error to occur</h3>
          <pre class="exception">{{ submission.exception }}</pre>
        </div>
        <EntityView
          :visibility="{
            failures: showFailures,
            warnings: showWarnings,
            tips: showTips,
            info: showInfo,
            empty: showEmpty,
          }"
          :root="submission.entity"
        />
      </div>
    </div>
  </div>
  <Loading v-else />
</template>

<script>
export default {
  name: 'submission',
  data() {
    return {
      loaded: false,
      submission: {
        name: undefined,
        verdict: undefined,
        uml: undefined,
        pipelineLog: {
          submitted: { nano: undefined, epochSecond: undefined },
          dispatched: { nano: undefined, epochSecond: undefined },
          analysisDone: { nano: undefined, epochSecond: undefined },
          reportDone: { nano: undefined, epochSecond: undefined },
        },
      },
      showAll: false,
      showWarnings: true,
      showTips: false,
      showEmpty: false,
      showFailures: true,
      showInfo: true,
      verdict: undefined,
      badge: undefined,
      assignment: {
        id: undefined,
        umlFormatterSrc: undefined,
      },
    };
  },
  async mounted() {
    this.submissionRefresher = window.setInterval(this.fetchSubmission, 1000);
    await this.fetchSubmission();
  },
  async beforeRouteUpdate(to, from, next) {
    this.submissionRefresher = window.setInterval(this.fetchSubmission, 1000);
    await this.fetchSubmission();
    next();
  },
  beforeDestroy() {
    this.loaded = false;
    window.clearInterval(this.submissionRefresher);
  },
  methods: {
    /**
     * Looks up a div on the page. Uses the e-FULL_NAME id each entity has.
     */
    lookupDiv(entity) {
      return document.getElementById(this.generateId(entity));
    },
    /**
     * Action that happens when the showAll button is pressed. Changes the state of
     * all show[] variables.
     */
    showAllToggle() {
      this.showAll = !this.showAll;
      this.showInfo = this.showAll;
      this.showWarnings = this.showAll;
      this.showTips = this.showAll;
      this.showFailures = this.showAll;
    },
    /**
     * Fetches a submission.
     */
    async fetchSubmission() {
      const { aid, sid } = this.$route.params;
      this.$auta.getAssignment(aid).then((a) => this.assignment = a);
      this.submission = await this.$auta.getSubmission(aid, sid);
      if (this.submission.verdict) {
        this.verdict = this.submission.verdict;
      } else {
        this.verdict = await this.submission.getVerdict();
      }
      if (this.verdict !== 'pending') {
        window.clearInterval(this.submissionRefresher);
      }
      await this.getBadge(aid, sid);
      this.loaded = true;
    },
    /**
     * Fetches the badge from the server, and updates the badge container on the page with the
     * badge.
     *
     * @param aid {string} the assignment id
     * @param sid {string} the submission id
     * @returns {Promise<void>} an empty promise
     */
    async getBadge(aid, sid) {
      // Check if the verdict was not an error, and if there is not already a badge.
      if (this.verdict && this.verdict !== 'error' && this.verdict !== 'pending' && !this.badge) {
        this.badge = await this.$auta.getBadge(aid, sid);
      }
    },
    /**
     * Translates a serialized Java Instant into a float containing the corresponding UNIX
     * epoch timestamp.
     *
     * @param ts the instant to convert
     *
     * @returns {Number} the timestamp
     */
    instantToUnixSeconds(ts) {
      return ts.epochSecond + ts.nano / 1000000000.0;
    },

    /**
     * Formats the date the submission was submitted as a string.
     *
     * @returns {string} the formatted date
     */
    formatSubmittedDate() {
      const submittedInstant = this.submission.pipelineLog.submitted;
      if (!submittedInstant) {
        return 'not yet';
      }

      const time = this.instantToUnixSeconds(submittedInstant);
      return this.$moment.unix(time).calendar(null, { sameElse: 'Y-MM-DD HH:mm:ss' });
    },

    /**
     * Returns the moment the submission was last known to be in the queue.
     *
     * If the submission contains no such information, the submission is assumed to still be in
     * the queue and the current time is returned.
     *
     * @returns {moment.Moment} the moment
     */
    getTimeLastInQueue() {
      const dispatchedInstant = this.submission.pipelineLog.dispatched;
      if (dispatchedInstant) {
        return this.$moment.unix(this.instantToUnixSeconds(dispatchedInstant));
      }

      return this.$moment.now();
    },

    /**
     * Formats the time the submission spent in the submission queue.
     *
     * @returns {string} the formatted duration
     */
    formatEnqueued() {
      const subTime = this.instantToUnixSeconds(this.submission.pipelineLog.submitted);

      // eslint-disable-next-line arrow-body-style
      return this.withTimeRoundingDisabled(() => {
        return this.$moment.unix(subTime).to(this.getTimeLastInQueue(), true);
      });
    },

    /**
     * Returns the moment the submission was last known to be in the analysis stage.
     *
     * If the submission contains no such information, the submission is assumed to be still
     * under analysis and the current time is returned.
     *
     * @returns {moment.Moment} the moment
     */
    getTimeLastInAnalysis() {
      const analyzedInstant = this.submission.pipelineLog.analysisDone;
      if (analyzedInstant) {
        return this.$moment.unix(this.instantToUnixSeconds(analyzedInstant));
      }

      return this.$moment.now();
    },

    /**
     * Formats the time the submission spent in analysis.
     *
     * @returns {string} the formatted duration
     */
    formatAnalyzed() {
      const dispatchedInstant = this.submission.pipelineLog.dispatched;
      if (!dispatchedInstant) {
        return '';
      }

      return this.withTimeRoundingDisabled(() => {
        const timeSpentInAnalysis = this.getTimeLastInAnalysis();
        return this.$moment.unix(this.instantToUnixSeconds(dispatchedInstant))
          .to(timeSpentInAnalysis, true);
      });
    },

    /**
     * Formats the timestamp the submission was fully processed by the system.
     *
     * @returns {string} the formatted timestamp
     */
    formatProcessed() {
      const reportInstant = this.submission.pipelineLog.reportDone;
      if (!reportInstant) {
        return '';
      }

      const time = this.instantToUnixSeconds(reportInstant);
      return this.$moment.unix(time).calendar(null, { sameElse: 'Y-MM-DD HH:mm:ss' });
    },

    /**
     * Executes a function with Moment.js time rounding disabled.
     *
     * The rounding configuration is automatically restored to its previous value.
     *
     * @param fn the function to execute
     *
     * @returns {*} what {@code fn} returned
     */
    withTimeRoundingDisabled(fn) {
      const defaultRounding = this.$moment.relativeTimeRounding();
      this.$moment.relativeTimeRounding((x) => x);

      const ret = fn();

      this.$moment.relativeTimeRounding(defaultRounding);

      return ret;
    },
  },
};
</script>

<style scoped>
h5 a {
  float:right;
}
button.active {
  background-color: #008cba;
  color: white;
}

div.test-entity {
  background-color: rgba(11, 192, 0, 0.22);
}

div.entity-container {
  border:black;
}

div.test-legend {
  background-color: rgba(11, 192, 0, 0.22);
  height: 2em;
  width: 2em;
  border-style: solid;
}

div.source-legend {
  background-color: white;
  height: 2em;
  width: 2em;
  border-style: solid;
}

.btn {
  margin: 0px 1px;
}
.submission-header {
  display: flex;
  justify-content: space-between;

  width: 100%
}

.submission-name {
  display: inline-block;

  max-width: 75%;
}

.status-card {
  display: flex;
  flex-direction: column;
}

.status-image, .uml-preview {
  display: block;

  width: 160px;
  height: 160px;

  margin: 0 auto;
}

.status-line {
  display: inline-block;

  font-size: 2rem;
  font-variant: small-caps;
  text-align: center;
}

.sub-status-line {
  display: inline-block;

  font-size: 1.2rem;
  font-variant: small-caps;
  text-align: center;
}

.aside > * {
  padding-top: .6rem;
  padding-bottom: .6rem;

  border-bottom: 1px solid lightgray;
}

.aside > *:first-child {
  padding-top: 0;
}

.aside > *:last-child {
  padding-bottom: 0;

  border-bottom: none;
}

.aside dd:empty::after {
  color: gray;
  content: '(not yet)';
}

.uml-container {
  width: 100%;
  height: 100%;

  overflow: scroll;
}

.uml-preview {
  cursor: zoom-in;
}

.exception {
  white-space: pre-wrap;
}

</style>
