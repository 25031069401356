<template>
  <div v-if="loaded">
    <div class="row">
      <div class="col-12" id="breadcrumbs">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'assignments' }">Assignments</router-link>
          </li>
          <li class="breadcrumb-item">
            {{ assignment.name }}
          </li>
        </ol>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-2">
        <button class="btn btn-default" @click.prevent="showUploadModal">Upload submission</button>
      </div>
      <div class="col-1 text-right">
        <router-link :to="`/assignment/${this.aid}/edit`">
          <button class="btn btn-default"><Fa ico="edit" /></button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12" id="assignment">
        <table class="table">
          <tr>
            <th>File</th>
            <th>Identity</th>
            <th class="verdict">Verdict</th>
          </tr>
          <tr v-for="submission in submissions" :key="submission.id">
            <td>
              <router-link
                :to="{ name: 'submission', params: { aid: assignment.id, sid: submission.id } }">
                {{ submission.name }}
              </router-link>
            </td>
            <td v-if="submission.identity">
                {{ submission.identity }}
            </td>
            <td v-else>
              <i class="anonymous">(anonymous)</i>
            </td>

            <td>
              <img :src="`/img/auta-${submission.verdict || 'pending'}-32x32.png`" />
            </td>
          </tr>
        </table>

        <paginate
          v-model="pageNumber"
          :page-count="numPages"
          :click-handler="onPageChange"
          prev-text="Prev"
          next-text="Next"
          container-class="pagination justify-content-center"
          page-class="page-item"
          page-link-class="page-link"
          next-class="page-item"
          next-link-class="page-link"
          prev-class="page-item"
          prev-link-class="page-link"
        >
        </paginate>
      </div>
    </div>
    <modal name="upload" classes="v--modal modal-container" height="auto">
      <div class="modal-header-container">
        <h1>Upload manually</h1>
      </div>
      <div>
        <form class="form-inline">
          <input ref="file" class="form-control" type="file">
          <button class="btn btn-primary" type="submit" @click.prevent="upload">Upload</button>
        </form>
      </div>
    </modal>
  </div>
  <Loading v-else />
</template>

<style scoped>
th.verdict {
  width: 32px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}
i.anonymous {
  color: #b4b4b4;
}
</style>

<script>
export default {
  name: 'assignment',
  data() {
    return {
      assignment: null,
      loaded: false,
      submissions: [],
      numPages: 0,
      pageNumber: +(this.$route.query.page || 1),
      pageSize: +(this.$route.query.pageSize || 25),
      aid: this.$route.params.aid,
    };
  },
  mounted() {
    this.populate(this.$route.params.page, this.$route.params.pageSize);
  },
  async beforeRouteUpdate(to, _, next) {
    await this.populate(to.query.page, to.query.pageSize);
    next();
  },
  unmounted() {
    this.depopulate();
  },
  methods: {
    async populate(page, pageSize) {
      this.loaded = false;

      this.pageNumber = +(page || 1);
      this.pageSize = +(pageSize || 25);

      this.assignment = await this.$auta.getAssignment(this.aid);

      const res = await this.$auta.getSubmissions(this.aid, this.pageNumber, this.pageSize);
      res.data.forEach(s => s.verdict = undefined); // So VueJS knows to watch this property
      this.submissions = res.data;

      this.numPages = res.numPages;

      this.submissions.forEach(s => s.getVerdict().then(v => (s.verdict = v)));

      this.loaded = true;
    },
    depopulate() {
      this.loaded = false;
      this.submissions = [];
    },
    onPageChange(num) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: { page: num, pageSize: this.pageSize },
      });
    },
    async upload() {
      const genIdPart = () => Math.floor(Math.random() * 0x7FFFFFFF).toString(36);
      const file = this.$refs.file.files[0];
      const fd = new FormData();
      fd.set('file', file);
      fd.set('identity', `ui:${(await this.$auta.getUserInfo()).name}`);
      fd.set(
        'directUploadId',
        `ui:${genIdPart()}-${genIdPart()}-${genIdPart()}-${genIdPart()}`,
      );

      const submission = await this.$auta.upload(this.aid, fd);

      this.$router.push({ name: 'submission', params: { aid: this.aid, sid: submission.id } });
    },
    showUploadModal() {
      this.$modal.show('upload');
    },
  },
};
</script>
