<template>
  <div>
    <div v-for="entity in entityGenerator(root)"
         v-show="visibility.empty || entityHasChildrenWithVisibleNotes(entity)"
         :data-hasreport="hasReport(entity)"
         :class="['p-3 mb-3 shadow ',`entity-${lowercase(entity.level)}`,
               (entity.testEntity) ? 'test-entity' : '']"
         :key="generateId(entity)" :id="generateId(entity)">
      <h5>
        {{`${capitalize(entity.level)} - ${simpleName(entity)}` }}
      </h5>
      <NoteView :entity="entity" :visibility="visibility" />
    </div>
  </div>
</template>

<script>
import Severity from '../model/Severity.js';

export default {
  props: ['visibility', 'root'],
  methods: {
    /**
     * A generator for entities starting with the root and iterating in a depth-first, pre-order
     * order.
     *
     * @param root the root entity to traverse
     *
     * @returns {IterableIterator<Entity|IterableIterator<*|any>|*|any|any>}
     */
    * entityGenerator(root) {
      if (!root) return;
      // eslint-disable-next-line no-restricted-syntax
      for (const child of root.childGenerator()) {
        yield child;
      }
    },
    /**
     * Generates an ID for an entity.
     * @param {Entity} entity the entity to generate an id for
     * @return {string} the generated ID.
     */
    generateId(entity) {
      return `e-${this.fullName(entity)}`;
    },
    /**
     * Checks if an entity has notes, or has any descendants with visible notes.
     *
     * @param {Entity} entity the entity
     * @return {boolean} true if the entity (or descendants) has any notes
     */
    entityHasChildrenWithVisibleNotes(entity) {
      if (this.hasVisibleReport(entity)) {
        return true;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const child of entity.childGenerator()) {
        if (this.hasVisibleReport(child)) {
          return true;
        }
      }
      return false;
    },
    /**
     * Checks if this entity has any visible reports.
     *
     * Checks if the showWarnings/showTips/showFailures variables are enabled and sums the number
     * of reports with the enabled severities.
     *
     * Does not check children.
     *
     * @param {Entity} entity the entity to analyze.
     * @returns {boolean} true if there are any visible reports
     */
    hasVisibleReport(entity) {
      let returnArray = [];
      if (this.visibility.warnings) {
        returnArray = returnArray.concat(this.getNotesBySeverity(entity, Severity.WARNING));
      }
      if (this.visibility.tips) {
        returnArray = returnArray.concat(this.getNotesBySeverity(entity, Severity.TIP));
      }
      if (this.visibility.failures) {
        returnArray = returnArray.concat(this.getNotesBySeverity(entity, Severity.FAILURE));
      }
      return returnArray.length > 0;
    },
    /**
     * Gets the full name of an entity
     * @return {string} the full name
     */
    fullName(entity) {
      const name = this.simpleName(entity);
      if (!entity.parent) {
        return name;
      }
      return `${this.fullName(entity.parent)}/${name}`;
    },
    /**
     * Returns the simple (own) name of an entity.
     *
     * @return {string} the name
     */
    simpleName(entity) {
      return (typeof entity.name === 'string') ? entity.name : entity.name.name;
    },
    /**
     * Checks whether the given entity has a report.
     *
     * @param entity the entity to check
     * @returns {boolean} true if the entity has a report, falsy otherwise
     */
    hasReport(entity) {
      return entity
        && entity.reports
        && entity.reports.map(n => n.notes.length).reduce((a, b) => a + b, 0) > 0;
    },
  },
};
</script>

<style scoped>
.entity-file {
  margin-left:2em;
}

.entity-class {
  margin-left:4em;
}

.entity-method {
  margin-left:6em;
}

.entity-field {
  margin-left:6em;
}
</style>
