/**
 * A description of a worker.
 */
export default class Worker {
  static parse(obj) {
    if (!obj) return null;

    return new Worker(
      obj.name,
      obj.hostname,
      obj.load,
      obj.lastPing,
      obj.job,
    );
  }

  /**
   * Creates a new worker description.
   *
   * @param name the identifier of the worker
   * @param hostname the name of the host the worker is running on
   * @param load the current load on the worker's machine
   * @param lastPing the timestamp the last ping was received
   * @param job the job the worker is currently serving
   */
  constructor(name, hostname, load, lastPing, job) {
    this.name = name;
    this.hostname = hostname;
    this.load = load;
    this.lastPing = lastPing;
    this.job = job;
    Object.freeze(this);
  }

  /**
   * Returns the universal identifier of the worker.
   *
   * A universal identifier consists of the worker's hostname, a dash, and its identifier.
   *
   * @returns {string} the universal identifier
   */
  getUniversalIdentifier() {
    return `${this.hostname}-${this.name}`;
  }
}
