<template>
  <div v-if="loaded">
    <div class="row">
      <div class="col-12" id="breadcrumbs">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'courses' }">Courses</router-link>
          </li>
          <li class="breadcrumb-item">
            {{ `${course.courseCode} ${course.name} (${course.year})` }}
          </li>
        </ol>
      </div>
    </div>
    <div class="row flex-column justify-content-end">
      <div class="col-6">
        <button class="btn btn-default m-2" @click.prevent="showModal('assignment')">
          Add existing assignment
        </button>
        <router-link :to="{ path: '/assignment/new', query: { cid: cid }}">
          <button class="btn btn-default">
            Create new assignment
          </button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12" id="course">
        <table class="table">
          <tr>
            <th>Assignment name</th>
            <th class="action"><Fa ico="trash-alt" /></th>
          </tr>
          <tr v-for="assignment in assignments" :key="assignment.id">
            <td>
              <router-link :to="{ name: 'assignment', params: { aid: assignment.id } }">
                {{ assignment.name }}
              </router-link>
            </td>
            <td>
              <a class="action text-danger" href="javascript:void(0)"
                 @click.prevent="deleteAssignment(assignment.id)">
                <Fa ico="trash-alt" />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- User table -->
    <div class="row flex-column justify-content-end">
      <div class="col-2">
        <button class="btn btn-default" @click.prevent="showModal('user')">
          Add user
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12" id="users">
        <table class="table">
          <tr>
            <th>Users</th>
            <th>Role</th>
            <th class="action"><Fa ico="trash-alt" /></th>
          </tr>
          <tr v-for="teacher in course.instructorSet" :key="teacher">
            <td>
              <router-link :to="{ name: 'user', params: { user: teacher } }">
                {{ teacher }}
              </router-link>
            </td>
            <td>
              Instructor
            </td>
            <td>
              <a class="action text-danger" href="javascript:void(0)"
                 @click.prevent="removeTeacher(teacher)">
                <Fa ico="trash-alt" />
              </a>
            </td>

          </tr>
          <tr v-for="ta in course.taSet" :key="ta">
            <td>
              <router-link :to="{ name: 'user', params: { user: ta } }">
                {{ ta }}
              </router-link>
            </td>
            <td>
              TA
            </td>
            <td>
              <a class="action text-danger" href="javascript:void(0)"
                 @click.prevent="removeTA(ta)">
                <Fa ico="trash-alt" />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!--Assignment modal-->
    <modal name="assignment" classes="v--modal modal-container" height="auto">
      <div class="modal-header-container">
        <h1>Add assignment</h1>
      </div>
      <div>
        <form>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="assignmentList">Assignment list</label>
            <div class="col-md-8">
              <select name="assignmentList" v-model="addAid" required id="assignmentList"
                      class="form-control">
                <option disabled value="">Please select one</option>
                <option v-for="a in allAssignments" v-bind:value="a.id" v-bind:key="a.id">
                  {{ a.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex flex-row-reverse">
            <button class="btn btn-primary" type="submit" @click.prevent="addAssignment">
              Add
            </button>
          </div>
        </form>
      </div>
    </modal>

    <!--User modal-->
    <modal name="user" classes="v--modal modal-container" height="auto">
      <div class="modal-header-container">
        <h1>Add user</h1>
      </div>
      <div>
        <form>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="username">Username</label>
            <div class="col-md-9">
              <select name="username" id="username" ref="input" class="form-control"
                     v-model="addUser">
                <option  v-for="username in allUsers" v-bind:value="username"
                         v-bind:key="`user_${username}`">
                  {{ username }}
                </option>
              </select>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="role">Role</label>
            <div class="col-md-9">
              <select v-model="addRole" class="form-control" name="role" id="role">
                <option v-for="role in allRoles" v-bind:value="role"
                        v-bind:key="`select_role_${role}`">
                  {{ convertRoleNames(role) }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex flex-row-reverse">
            <button class="btn btn-primary" type="submit" @click.prevent="addUserAction">
              Add
            </button>
          </div>
        </form>
      </div>
    </modal>
  </div>
  <Loading v-else />
</template>

<style scoped>
  th.action {
    width: 1em;
    color: #A0A0A0;
  }

  td a.action {
    color: rgb(34, 34, 34);
  }
</style>

<script>
export default {
  name: 'course',
  data() {
    return {
      loaded: false,
      allUsers: [],
      allRoles: ['ROLE_TA', 'ROLE_TEACHER'],
      course: {
        id: undefined,
        name: undefined,
        courseCode: undefined,
        year: undefined,
        taSet: undefined,
        instructorSet: undefined,
        assignmentIds: undefined,
      },
      cid: this.$route.params.cid,
      assignments: undefined, // The assignments belonging to the current course
      allAssignments: undefined, // All assignments that don't belong to the current couese
      addAid: '', // The AID that will be added to the course
      addUser: '', // The username of the user that will be added to the course
      addRole: 'ROLE_TA', // The role of the user that will be added to the course
    };
  },
  created() {
    this.fetchCourse();
  },
  async beforeRouteUpdate(to, from, next) {
    await this.fetchCourse();
    next();
  },
  unmounted() {
    this.depopulate();
  },
  methods: {
    /**
     * Fetches the course from the core
     */
    async fetchCourse() {
      this.loaded = false;
      this.course = await this.$auta.getCourse(this.cid);
      if (this.course !== undefined) {
        this.assignments = [];
        await this.fetchCourseAssignments();
      }
      await this.fetchAllAssignments();
      await this.getUsers(1, 25);
      this.loaded = true;
    },
    depopulate() {
      this.loaded = false;
      this.course = undefined;
      this.assignments = [];
      this.allUsers = [];
    },
    /**
     * Gets a list of user names that have been registered. Filters users that are disabled.
     *
     * @param pageNumber {number} the page number to fetch
     * @param pageSize {number} the page size to fetch
     */
    async getUsers(pageNumber, pageSize) {
      const page = await this.$auta.getUsers(pageNumber, pageSize);
      this.allUsers = page.data.filter(u => u.enabled).map(u => u.name);
    },

    /**
     * Fetches all assignments that do NOT belong to this course , and updates the
     * this.allAssignment variable
     *
     * @returns {Promise<void>}
     */
    async fetchAllAssignments() {
      const assignments = await this.$auta.getAssignments();
      this.allAssignments = assignments.filter(a => !this.course.assignmentIds.includes(a.id));
    },

    /**
     * Fetches all assignments that DO belong to this course, updates the this.assignments variable.
     *
     * @returns {Promise<void>}
     */
    async fetchCourseAssignments() {
      this.assignments = await Promise.all(this.course.assignmentIds.map(
        aid => this.$auta.getAssignment(aid),
      ));
    },

    /**
     * Adds an assignment to this course, called from the modal.
     *
     * @returns {Promise<void>}
     */
    async addAssignment() {
      await this.$auta.addAssignmentToCourse(this.course, this.addAid);
      await this.fetchCourse(this.addAid);
      this.addAid = '';
      this.$modal.hide('assignment');
    },

    /**
     * Shows a modal.
     *
     * @param name the name of the modal
     */
    showModal(name) {
      this.$modal.show(name);
    },

    /**
     * Adds a user to the course.
     *
     * @returns {Promise<void>}
     */
    async addUserAction() {
      if (this.addRole === 'ROLE_TA') {
        await this.addTA();
      } else {
        await this.addTeacher();
      }
      this.fetchCourse();
      this.addUser = '';
      this.$modal.hide('user');
    },

    /**
     * Adds a teacher to the course.
     *
     * @returns {Promise<void>}
     */
    async addTeacher() {
      await this.course.addTeacher(this.addUser);
    },
    /**
     * Adds a TA to the course.
     *
     * @returns {Promise<void>}
     */
    async addTA() {
      await this.course.addTA(this.addUser);
    },

    /**
     * Removes a teacher from the course.
     *
     * @param username the username of the teacher to remove.
     * @returns {Promise<void>}
     */
    async removeTeacher(username) {
      await this.course.removeTeacher(username);
      this.fetchCourse();
    },

    /**
     * Removes a TA from a course.
     *
     * @param username the username of the TA
     * @returns {Promise<void>}
     */
    async removeTA(username) {
      await this.course.removeTA(username);
      this.fetchCourse();
    },

    /**
     * Deletes an assignment from a course
     *
     * @param aid
     * @returns {Promise<void>}
     */
    async deleteAssignment(aid) {
      await this.$auta.removeAssignmentFromCourse(this.course, aid);
      this.fetchCourse();
    },
  },
};
</script>
