/**
 * A description of a user.
 */
export default class User {
  /**
   * Creates a new user description.
   *
   * @param username the name of the user
   * @param authorities the list of authorities the user has been granted
   * @param enabled if {@code false}, the user can not log in
   */
  constructor(username, authorities, enabled) {
    this.name = username;
    this.authorities = authorities;
    this.enabled = enabled;
    Object.freeze(this);
  }
}
