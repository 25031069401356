<template>
  <div class="row" v-if="loaded">
    <div class="col-12 py-md-3">
      <div class="btn-toolbar justify-content-between" role="toolbar"
           aria-label="Assignment operations">
        <router-link to="/assignment/new">
          <button type="button" class="btn btn-primary">
            <i class="fas fa-plus"></i>
            New
          </button>
        </router-link>
        <div class="input-group">
          <label for="search" class="sr-only">Search</label>
          <input type="search" id="search" name="search" placeholder="Search" />
        </div>
      </div>

      <div id="assignments">
        <table class="table">
          <tr>
            <th>Title</th>
            <th class="action"><Fa ico="edit" /></th>
            <th class="action"><Fa ico="clone" /></th>
            <th class="action"><Fa ico="trash-alt" /></th>
          </tr>
          <tr v-for="assignment in assignments" :key="assignment.id">
            <td>
              <router-link :to="'/assignment/' + assignment.id">{{ assignment.name }}</router-link>
            </td>
            <td>
              <router-link :to="'/assignment/' + assignment.id + '/edit'" class="action">
                <Fa ico="edit" />
              </router-link>
            </td>
            <td>
              <router-link :to="'/assignment/' + assignment.id + '/clone'" class="action">
                <Fa ico="clone" />
              </router-link>
            </td>
            <td>
              <a class="action text-danger" href="javascript:void(0)"
                 @click.prevent="deleteAssignment(assignment.id)">
                <Fa ico="trash-alt" />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <Loading v-else />
</template>

<style scoped>
  th.action {
    width: 1em;
    color: #A0A0A0;
  }

  td a.action {
    color: rgb(34, 34, 34);
  }
</style>

<script>
export default {
  name: 'assignments',
  data() {
    return {
      loaded: false,
      assignments: [],
    };
  },
  mounted() {
    this.fetchAssignments();
  },
  async beforeRouteUpdate(to, from, next) {
    await this.fetchAssignments();
    next();
  },
  unmounted() {
    this.loaded = false;
    this.assignments = [];
  },
  methods: {
    async fetchAssignments() {
      this.loaded = false;
      this.assignments = await this.$auta.getAssignments();
      this.loaded = true;
    },
    deleteAssignment(aid) {
      this.$auta.deleteAssignment(aid);
      this.assignments = this.assignments.filter(a => a.id !== aid);
    },
  },
};
</script>
