/**
 * A page of results.
 */
export default class Page {
  /**
   * Creates a new page.
   *
   * @param data the data contained within the page
   * @param page the current page number
   * @param pageSize the maximum number of elements on this page
   * @param numPages the total number of pages available
   */
  constructor(data, page, pageSize, numPages) {
    this.data = data;
    this.page = page;
    this.pageSize = pageSize;
    this.numPages = numPages;
    Object.freeze(this);
  }
}
