import Client from './Client.js';
import BrowserLocallyCachedAuthProvider from './BrowserLocallyCachedAuthProvider.js';
import AxiosHttp from './AxiosHttp.js';

/**
 * The library's entry point.
 */
export default class AuTA {
  /**
   * Creates a new Client instance.
   *
   * @param config the client configuration
   *
   * @returns {Client} the client
   */
  static client(config) {
    config.baseUrl = config.baseUrl || window.location.origin;
    config.authProvider = config.authProvider || new BrowserLocallyCachedAuthProvider();
    config.http = config.http || new AxiosHttp(config.baseUrl, config.authProvider, config.httpDebug);
    return new Client(config);
  }

  /**
   * Returns the version auta.js was built as.
   *
   * @returns {string} version
   */
  static version() {
    // eslint-disable-next-line no-undef
    return VERSION;
  }

  static BrowserLocallyCachedAuthProvider() {
    return new BrowserLocallyCachedAuthProvider();
  }
}
