/**
 * An authentication token.
 */
export default class Token {
  /**
   * Creates a new authentication token.
   *
   * @param value the value of the token
   * @param expiry the expiry timestamp of the token (if any)
   */
  constructor(value, expiry) {
    this.token = value;
    this.expiry = expiry;
    Object.freeze(this);
  }
}
