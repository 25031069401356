/**
 * A Docker authentication configuration object.
 */
export default class AuthConfiguration {
  /**
   * Parses an authentication configuration object from an object.
   *
   * @param obj? the object to parse the configuration from
   *
   * @returns {AuthConfiguration|null}
   */
  static parse(obj) {
    if (!obj) return null;

    return new AuthConfiguration(obj.registry, obj.username, obj.password);
  }

  /**
   * Creates a new authentication configuration object.
   *
   * @param registry the registry to authenticate to
   * @param username the username to authenticate with
   * @param password the password to authenticate with
   */
  constructor(registry, username, password) {
    this.registry = registry;
    this.username = username;
    this.password = password;
  }
}
