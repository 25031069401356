<template>
  <div>
    <div class="form-group row">
      <label class="col-md-2 col-form-label preset-selector-label"
             :for="presetSelectorId">
        {{ title }}
      </label>
      <select :id="presetSelectorId" :name="presetSelectorId"
              ref="presetSelector" class="col-md-8"
              @change="changePreset"
      >
        <option v-for="(preset, id) in presets"  :key="id" :value="id"
                :selected="id === selectedPreset">
          {{ preset.name }}
        </option>
      </select>
      <span v-show="selectedPreset !== 0" class="script-toggle col-md-2 col-form-label"
            @click="toggleScriptVis">
        <Fa pfx="fas" :ico="`chevron-${open ? 'up' : 'down'}`" />
        {{ open ? 'Hide script' : 'Show script' }}
      </span>
    </div>
    <div v-show="open">
      <div class="script-editor" ref="ace"></div>
      <div class="script-tests col-md-12" v-if="kind === 'criteria'">
        <h5>Test script</h5>
        <table class="table">
          <tr>
            <th class="test-value">Value</th>
            <th class="test-remove"><Fa pfx="fas" ico="minus" title="Remove"/></th>
          </tr>
          <tr v-for="test in tests" :key="`test/${test.id}`">
            <td><input class="form-control" type="text" v-model="test.value" /></td>
            <td class="test-remove" @click="removeTest(test.id)">
              <Fa pfx="fas" ico="minus" title="Remove"/>
            </td>
          </tr>
        </table>
        <div class="test-buttons">
          <button class="btn btn-success run-tests" @click.prevent="runTests">
            <Fa pfx="fas" ico="glasses" /> Test script
          </button>
          <div>
            <button class="btn btn-primary suggest-tests" @click.prevent="suggestTests">
              <Fa pfx="fas" ico="fill-drip" /> Auto-fill values
            </button>
            <button class="btn btn-primary add-test" @click.prevent="addTest">
              <Fa pfx="fas" ico="plus" /> Add new test
            </button>
          </div>
        </div>
        <div class="test-results" v-if="compilationStatus !== null">
          <h5>Test results</h5>
          <div class="test-compilation-results">
            <p>
              Script {{ compilationStatus === true
              ? 'compiles'
              : `does not compile: ${compilationStatus}` }}
            </p>
          </div>
          <div v-for="test in tests" :key="`test-result/${test.id}`">
            <p v-if="test.error">
              {{ test.error }}
            </p>
            <EntityView :root="test.fakeEntity"
                        :visibility="{ failures: true, warnings: true, tips: true, info: true }"
            />
          </div>
        </div>
      </div>
      <div class="script-support col-md-12" v-if="kind === 'criteria'">
        <h5>Scripting help</h5>
        <p>
          <code>results</code> is a(n) {{ beautifyMetricTypeName(metric.value_type) }}.
        </p>
        <p>
          Scripts are run in an
          <a href="https://www.ecma-international.org/ecma-262/6.0/index.html">ECMAScript 6</a>-compliant
          interpreter. In addition to the standard JavaScript functions, AuTA provides special
          functions and constants in the script's scope to allow it to return feedback to the
          system.
        </p>
        <p>
          The runtime environment keeps a set of identifiers reserved, including the names of the
          feedback functions and access levels below, as well as any identifier starting with
          <code>$__</code> (single dollar sign, double underscores) for internal
          datastructures and subroutines.
        </p>
        <h6>Feedback functions</h6>
        <p>
          A number of functions that add feedback to the report are available and documented below.
        </p>
        <dl>
          <dt><code>info([whom,] feedback)</code></dt>
          <dd>
            Add generic information to the feedback. Can be used to print raw metric values
            without affecting the verdict. Use with caution - heavy use of this function can slow
            down processing.
          </dd>

          <dt><code>tip([whom,] feedback)</code></dt>
          <dd>
            Add a tip for the submitter to the feedback. Tips are often intended to inform the
            student why their feedback was generated and should be very detailed. For brevity,
            the tips are often aggregated into a single block, with a list of entities that caused
            this tip to appear.
          </dd>

          <dt><code>warn([whom,] feedback)</code></dt>
          <dd>
            Add a warning for the submitter to the feedback. Warnings should indicate that the
            metric is still acceptable, but very close to being rejected, or that the submission
            contains questionable decisions. Best paired with a tip explaining why this warning
            was raised in detail.
          </dd>

          <dt><code>fail([whom,] feedback)</code></dt>
          <dd>
            Add a failure for the submitter to the feedback. Failures directly affect the verdict
            of the entire submission, causing it to be rejected for most configurations. This should
            only be used for submissions that are totally unacceptable in this state. Like warnings,
            best paired with a tip explaining why this submission fails to meet the assignment's
            requirements.
          </dd>
        </dl>
        <h6>Access levels</h6>
        <p>
          Access levels determine who is allowed to see the generated feedback. For all feedback
          functions, the access level can be set using the optional first parameter
          <code>whom</code>. Users with "higher" access levels (towards <code>ADMIN</code>) can
          always access the feedback for lower levels unless stated otherwise. The following
          levels are available in scripts:
        </p>
        <dl>
          <dt><code>PUBLIC</code></dt>
          <dd>
            The feedback is available for everyone, including other people than the submitter.
            AuTA does not allow public access to submissions via its own system, but this value
            could be used by (third-party) integrations.
          </dd>

          <dt><code>SUBMITTER</code></dt>
          <dd>The feedback is available for the person or group who submitted the submission.</dd>

          <dt><code>EDUCATIONAL</code> or <code>EDU_STAFF</code></dt>
          <dd>The feedback is only available for TAs and instructors.</dd>

          <dt><code>INSTRUCTOR</code></dt>
          <dd>
            The feedback is only available for instructors. <strong>Not recommended</strong> as
            TAs will not be able to read the feedback.
          </dd>

          <dt><code>ADMINISTRATIVE</code> or <code>ADMIN</code></dt>
          <dd>
            The feedback is only available to the system administrator. Used internally to display
            system errors, and usable in scripts for completeness reasons. Unlikely to be useful
            in scripts, however.
          </dd>
        </dl>
        <p>
          If no access level is given, <code>PUBLIC</code> is assumed.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as Ace from 'ace-builds';
import 'ace-builds/webpack-resolver';

export default {
  data() {
    return {
      presetSelectorId: `preset-selector-${Math.random()}`,
      open: false,
      selectedPreset: 0,
      editorStyle: {
        display: this.open ? 'block' : 'none',
      },
      showScriptToggler: true,
      tests: [],
      compilationStatus: null,
    };
  },
  created() {
    this.presets = this.scriptPresets.slice();
    this.presets.push({
      name: 'Custom....',
      custom: true,
      script: this.customTemplate || '',
    });

    this.presets.unshift({
      name: 'None',
      script: '',
    });
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.editor = Ace.edit(this.$refs.ace);
      this.editor.session.setMode(`ace/mode/${this.mode || 'javascript'}`);
      this.editor.setOption('printMarginColumn', 100);
      this.editor.setOption('maxLines', 24);

      let script = '';
      let currentPresetIndex = 0;

      if (this.currentScript) {
        const correspondingOption = this.presets.find(p => p.script === this.currentScript);
        currentPresetIndex = correspondingOption
          ? this.presets.indexOf(correspondingOption)
          : this.presets.length - 1;

        script = this.currentScript;
      }

      const currentPreset = this.presets[currentPresetIndex];

      this.selectedPreset = currentPresetIndex;
      this.editor.setValue(script, -1);
      this.open = currentPreset.custom;
      this.tests = this.settings.passingScriptTestCases || [];
    },
    toggleScriptVis() {
      this.open = !this.open;

      if (this.open) {
        this.editor.renderer.updateFull();
      }
    },
    changePreset() {
      const currentScript = this.editor.getValue();

      if (this.presets.every(p => currentScript !== p.script)) {
        // TODO: warn if the user changes the preset while the script was modified
      }

      this.selectedPreset = parseInt(this.$refs.presetSelector.value, 10);
      const preset = this.presets[this.selectedPreset];
      this.editor.setValue(preset.script, -1);

      if (preset.custom && !this.open) {
        this.open = true;
      } else if (this.selectedPreset === 0 /* None */) {
        this.open = false;
      }
    },
    getScript() {
      return this.editor.getValue();
    },
    /**
     * Returns the unit tests set for the current script.
     *
     * Modifies the editor instance by removing the entities generated by tests.
     */
    getTests() {
      this.tests.forEach(t => delete t.fakeEntity);
      return this.tests;
    },
    /**
     * Converts a metric type name from a class name into a human-readable name.
     *
     * The raw name is one produced by Java's Class#getSimpleName(). This function transforms it
     * by splitting the string on its capitals, removing the trailing Metric part, joining it
     * separated by spaces, and converting the entire name to lowercase.
     *
     * For example, a name like {@code StringListMetric} will become "string list".
     *
     * @param rawName the raw class name
     * @returns {string} the friendly name
     */
    beautifyMetricTypeName(rawName) {
      return rawName.replace(/[A-Z]/g, ' $&')
        .split(' ')
        .slice(0, -1)
        .join(' ')
        .toLowerCase();
    },
    addTest() {
      this.tests.push({
        id: this.genRandomId('test'),
        value: 0,
        fakeEntity: {},
        error: undefined,
      });
    },
    removeTest(id) {
      this.tests.splice(this.tests.findIndex(t => t.id === id), 1);
    },
    /**
     * Runs the tests for the current script.
     *
     * @returns {Promise<boolean|string>} a compilation status
     */
    async runTests() {
      this.compilationStatus = null;
      this.tests.forEach(t => delete t.fakeEntity);
      const spec = {
        script: this.editor.getValue(),
        tests: this.tests,
      };
      const res = await this.$auta.testScript(spec);
      res.results.forEach((ts) => {
        const test = this.tests.find(t => t.id === ts.id);
        test.error = ts.error;
        test.fakeEntity = ts.fakeEntity;
        test.fakeEntity.name += ` for value ${JSON.stringify(test.value)}`;
      });
      this.compilationStatus = res.compiles;
      return this.compilationStatus;
    },
    async suggestTests() {
      const fixtures = await this.$auta.getMetricTestFixtures();
      fixtures[this.metric.name].forEach((v) => {
        this.addTest();
        this.tests[this.tests.length - 1].value = v;
      });
    },
  },
  props: [
    'title',
    'scriptPresets',
    'customTemplate',
    'currentScript',
    'mode',
    'metric',
    'kind',
    'settings',
  ],
};
</script>

<style scoped lang="less">
.script-toggle {
  cursor: pointer;
  user-select: none;
}

.script-toggle i {
  padding-right: 0.8em;
}

.script-editor {
  margin-bottom: 0.7rem;
}

.preset-selector-label {
  font-size: 15px;
}

.test-expected-note-count {
  width: 6em;
}

th.test-remove {
  color: gray;
}

.test-remove {
  color: red;
  cursor: pointer;
}

.script-tests, .test-buttons {
  margin-bottom: 15px;
}

.test-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
