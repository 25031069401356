/* eslint-disable max-classes-per-file */

/**
 * A reply to an HTTP request.
 */
class Reply {
  /**
   * Creates a new reply.
   *
   * @param data the payload
   * @param meta the response metadata, the properties of which will be adopted by the reply instance
   */
  constructor(data, meta) {
    this.data = data;
    Object.assign(this, meta);
    Object.freeze(this);
  }

  /**
   * Converts the reply to a string.
   *
   * @returns {string} the data as a string
   */
  toString() {
    return JSON.stringify(this.data);
  }
}

/**
 * An interface for HTTP implementations.
 */
export default class Http {
  // eslint-disable-next-line class-methods-use-this
  reply(data, meta) {
    return new Reply(data, meta);
  }

  /**
   * Performs a request.
   *
   * @param method the HTTP request method
   * @param url the URL to perform the request against
   * @param body the request body, if any
   * @param unauthenticated if true, no authentication token is attached (useful for /login calls)
   * @param bodyType the datatype of the body
   *
   * @returns {Promise<Object>} a promise containing the response to the request
   */
  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  request(method, url, body, unauthenticated, bodyType = 'application/json') {
    throw new TypeError('Method Http::request() is abstract');
  }

  /**
   * Performs a GET request.
   *
   * @param url the URL to perform the request against
   * @param unauthenticated if true, no authentication token is attached (useful for /login calls)
   * @param bodyType the datatype of the body
   *
   * @returns {Promise<Object>} a promise containing the response to the request
   */
  get(url, unauthenticated, bodyType) {
    return this.request('GET', url, undefined, unauthenticated, bodyType);
  }

  /**
   * Performs a POST request.
   *
   * @param url the URL to perform the request against
   * @param req the request body to send
   * @param unauthenticated if true, no authentication token is attached (useful for /login calls)
   * @param bodyType the datatype of the body
   *
   * @returns {Promise<Object>} a promise containing the response to the request
   */
  post(url, req, unauthenticated, bodyType) {
    return this.request('POST', url, req, unauthenticated, bodyType);
  }

  /**
   * Performs a PUT request.
   *
   * @param url the URL to perform the request against
   * @param req the request body to send
   * @param unauthenticated if true, no authentication token is attached (useful for /login calls)
   * @param bodyType the datatype of the body
   *
   * @returns {Promise<Object>} a promise containing the response to the request
   */
  put(url, req, unauthenticated, bodyType) {
    return this.request('PUT', url, req, unauthenticated, bodyType);
  }

  /**
   * Performs a DELETE request.
   *
   * @param url the URL to perform the request against
   * @param unauthenticated if true, no authentication token is attached (useful for /login calls)
   * @param bodyType the datatype of the body
   *
   * @returns {Promise<Object>} a promise containing the response to the request
   */
  delete(url, unauthenticated, bodyType) {
    return this.request('DELETE', url, undefined, unauthenticated, bodyType);
  }
}

Http.JSON_MIME = 'application/json';
Http.MULTIPART_MIME = 'multipart/form-data';
