/**
 * A metric.
 */
export default class Metric {
  /**
   * Parses a metric from an object.
   *
   * @param obj? the object to parse the metric from
   *
   * @returns {Metric|null}
   */
  static parse(obj) {
    if (!obj) return null;

    return new Metric(obj.name, obj.value);
  }

  /**
   * Creates a new metric.
   *
   * @param name the name of the metric
   * @param value the value of the metric
   */
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }

  /**
   * Returns the metric as a string in the following format:
   *
   * [NAME](VALUE)
   *
   * @returns {string}
   */
  toString() {
    return `[${this.name}](${JSON.stringify(this.value)})`;
  }
}
