<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="list-group-item">
    <div class="metric-header" @click="updateVisibility">
      <div>
        <h5 class="action accordion-state-chevron">
          <Fa pfx="fas" :ico="open ? 'chevron-up' : 'chevron-down'" />
        </h5>
        <h5> {{ beautifyMetricName(metric.name) }} </h5>
      </div>
      <div>
        <h5 class="action remove-metric" title="Remove" @click.stop="remove">
          <Fa pfx="fas" ico="times-circle" />
        </h5>
      </div>
    </div>
    <div class="metric-body" v-show="open">
      <ScriptContainer title="Passing Criteria"
                       ref="script"
                       :metric="metric"
                       :settings="settings"
                       :scriptPresets="metric.script_presets || []"
                       :currentScript="settings.passingScript"
                       :customTemplate="customScriptTemplate"
                       kind="criteria"
      />
      <ScriptContainer title="Formatter"
                       ref="formatter"
                       :metric="metric"
                       :settings="settings"
                       :scriptPresets="metric.format_presets || []"
                       :currentScript="settings.formattingScript"
                       :customTemplate="customFormatterTemplate"
                       kind="formatting"
                       v-show="false"
      />
      <ScriptContainer title="Dockerfile"
                       ref="dockerfile"
                       :metric="metric"
                       :settings="settings"
                       :current-script="settings.dockerfile"
                       :scriptPresets="metric.format_presets || []"
                       :customTemplate="dockerfileTemplate"
                       mode="dockerfile"
                       kind="dockerfile"
                       v-if="isDocker()"
      />
      <div v-if="isDocker()">
        <table id="registry-auth-table">
          <thead>
            <tr>
              <th>Registry</th>
              <th>Username</th>
              <th>Password</th>
              <th class="action"><Fa ico="trash-alt" /></th>
            </tr>
          </thead>
          <tbody>
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <tr v-for="authConfig in assignment.dockerAuthConfigurations">
              <td>
                <input type="text" v-model="authConfig.registry" placeholder="registry" />
              </td>
              <td>
                <input type="text" v-model="authConfig.username" placeholder="username" />
              </td>
              <td>
                <input type="password" v-model="authConfig.password" placeholder="password" />
              </td>
              <td>
                <a class="action text-danger" href="#"
                   @click.prevent="removeAuthConfig(authConfig.registry)">
                  <Fa ico="trash-alt" />
                </a>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <button class="btn btn-success" title="Add registry"
                        @click.prevent="assignment.dockerAuthConfigurations.push(
                          { registry: '', username: '', password: '' }
                        )">
                  <Fa ico="plus" />
                  <span class="sr-only">Add registry</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      dockerfileTemplate: `FROM debian:buster-slim

RUN mkdir -p /usr/share/man/man1
RUN apt update; apt upgrade -y; apt install -y openjdk-11-jre-headless maven wget

RUN mkdir /var/auta; mkdir /var/auta/test
WORKDIR /var/auta/test

RUN wget -O- http://localhost/assignment1.tar.gz | tar xvfz -

CMD cp -r /var/auta/submission/* /var/auta/test/assignment1/; mvn test
      `,
      customScriptTemplate: `(() => (results, templateResults) => {
    warn('Your script here...');
})();
`,
      customFormatterTemplate: `(() => results => {
    const pre = document.createElement('pre');
    pre.innerText = JSON.stringify(results);
    return pre;
})();
`,
    };
  },
  props: ['assignment', 'metric', 'metrics', 'settings'],
  methods: {
    updateVisibility() {
      this.open = !this.open;
    },
    /**
     * Emits a remove-metric event (which should be handled by a v-on:remove-metric) that includes
     * the settings object that was passed to this metric.
     */
    remove() {
      this.$emit('remove-metric', this.settings);
    },
    extractSettings() {
      const df = this.$refs.dockerfile ? this.$refs.dockerfile.getScript() : null;
      const settings = {
        name: this.metric.internal_name,
        script: this.$refs.script.getScript(),
        scriptTests: this.$refs.script.tests,
        formatter: this.$refs.formatter.getScript(),
        dockerfile: df,
        options: {},
      };
      Object.freeze(settings);
      return settings;
    },
    extractDockerAuthConfigurations() {
      return this.assignment.dockerAuthConfigurations;
    },
    beautifyMetricName(name) {
      return this.beautifiedName = name.split(/_/g)
        .map(s => s[0].toLocaleUpperCase() + s.substring(1).toLocaleLowerCase())
        .join(' ');
    },
    /**
     * Checks if the current metric requires Docker configuration.
     *
     * @return boolean {@code true} if the current metric requires Docker configuration,
     *                 {@code false} otherwise
     */
    isDocker() {
      return this.beautifyMetricName(this.metric.name).includes('Docker');
    },
    /**
     * Removes a Docker authentication configuration object.
     */
    removeAuthConfig(registry) {
      this.$delete(
        this.assignment.dockerAuthConfigurations,
        this.assignment.dockerAuthConfigurations.findIndex(c => c.registry === registry),
      );
    },
    /**
     * Validates the configuration for the metric.
     *
     * This also runs any unit tests.
     *
     * @return {Promise<boolean|string>} true if the settings are valid, a string containing
     *    an error otherwise
     */
    async validate() {
      const scriptStatus = await this.$refs.script.runTests();
      if (scriptStatus !== true) return scriptStatus;

      return true;
    },
  },
};
</script>

<style scoped lang="less">
#registry-auth-table {
  width: 100%;
}

.fab {
  padding-right: .5em;
}

th.action {
  width: 1em;
  color: #A0A0A0;
}

td a.action {
  color: rgb(34, 34, 34);
}

.action {
  width: 1.5em;

  text-align: center;
}

.form-action-row {
  padding-top: .8em;
}

.accordion-state-chevron {
  padding-right: 1.5em;
}

.clone-metric:hover {
  color: #00A6D6;
}

.remove-metric:hover {
  color: red;
}

.metric-header {
  display: flex;
  justify-content: space-between;

  cursor: pointer;
}

.metric-description {
  margin-left: 1em;

  cursor: text;
}

.metric-header h5 {
  display: inline-block;
}

.metric-header > div {
  display: inline-block;
}

.metric-body {
  margin-top: 0.5em;
  border-top: 1px solid #E0E0E0;
  padding-top: 0.5em;
}
</style>
