import Note from './Note.js';

/**
 * A report on a metric of an entity.
 */
export default class Report {
  /**
   * Parses a report from an object.
   *
   * @param obj? the object to parse the notes from
   *
   * @returns {Report|null}
   */
  static parse(obj) {
    if (!obj) return null;

    return new Report(obj.name, obj.notes.notes.map(Note.parse), obj.grade);
  }

  /**
   * Creates a new report.
   *
   * @param name the name of the metric the report is on
   * @param notes the notes in the report
   * @param grade the grade for the metrics
   */
  constructor(name, notes, grade) {
    this.name = name;
    this.notes = notes;
    this.grade = grade;
  }

  /**
   * Returns the notes in the report with the given severity.
   *
   * @param severity the severity to look for
   *
   * @returns {Array<Note>}
   */
  getNotesBySeverity(severity) {
    return this.notes.filter(n => n.severity === severity);
  }
}
