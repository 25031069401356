/**
 * A service providing endpoints for worker operations.
 */
export default class Workers {
  /**
   * Creates a workers service.
   *
   * @param client the client hosting this service
   */
  constructor(client) {
    this.client = client;
  }

  /**
   * Requests that the worker cancels its current job.
   *
   * @param worker the worker that should cancel its job
   * @param sid the submission ID of the job to cancel
   *
   * @returns {Promise<Object>}
   */
  async cancelJob(worker, sid) {
    return this.client.http.delete(`/api/v1/worker/${worker.getUniversalIdentifier()}/job/${sid}`);
  }

  /**
   * Requests that the worker be terminated.
   *
   * @param worker the worker to terminate
   *
   * @returns {Promise<Object>}
   */
  async kill(worker) {
    return this.client.http.delete(`/api/v1/worker/${worker.getUniversalIdentifier()}`);
  }

  /**
   * Returns the logs of the worker.
   *
   * @param worker the worker to get the logs of
   *
   * @returns {Promise<Array<string>>} a promise for an array of log entries
   */
  async getLogs(worker) {
    const logs = await this.client.http.get(
      `/api/v1/worker/${worker.getUniversalIdentifier()}/logs`,
    );
    /* eslint-disable  no-control-regex */
    return logs.data
      .split('\x0A\x1E')
      .map(e => e.replace(/\x1B(.)/g, '$1'));
  }
}
