c<template>
  <div class="loading-indicator container" ref="container">
    <div class="grid-x">
      <div class="cell">
        <div class="loading">
          <span v-if="!parts || parts.length === 0">
          Loading...
          </span>
          <ol v-else class="parts-list">
            <li class="progress-part" v-for="part in parts" :key="part.name">
              <span :class="['progress-icon', `progress-${part.status}`]">{{ part.status }}</span>
              <span class="part-name">{{ part.name }}</span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressPart from '../modules/ProgressPart.js';

export default {
  name: 'Loading',
  props: {
    parts: {
      type: Array[ProgressPart],
      default: () => [],
    },
  },
  data() {
    return {
      offset: 0,
      container: null,
      run: true,
      progress: null,
    };
  },
  mounted() {
    const { container } = this.$refs;
    this.container = container;
  },
  watch: {
    parts: {
      handler() {
        if (this.parts.length === 0) {
          this.progress = null;
          return;
        }

        this.progress = this.parts
          .map(p => (p.isDone() ? p.getContributingWeight() || (1.0 / this.parts.length) : 0))
          .reduce((a, b) => a + b, 0);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.loading-indicator {
  width: 100%;
}

.loading {
  margin: 0 auto;
  text-align: center;
  color: darkslategray;
}

.parts-list {
  .progress-part:nth-child(even) {
    background-color: #f3f3f3;
  }

  .progress-part {
    width: 100%;
    height: 1.25rem;

    display: block;
    text-align: left;

    .progress-icon {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      overflow: hidden;
    }

    .progress-pass {
      background-color: #4CAF50;
      color: #4CAF50;
    }

    .progress-warn {
      background-color: #FF9800;
      color: #FF9800;
    }

    .progress-pending {
      background-color: white;
      color: white;
    }

    .progress-fail {
      background-color: #EE3E34;
      color: #EE3E34;
    }

    .part-name {
      display: inline-block;
      height: 100%;
      width: calc(100% - 1.25rem);
      padding-left: 0.25rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
