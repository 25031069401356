<template>
  <div v-if="loaded">
    <div class="row flex-column justify-content-end">
      <div class="col-2">
        <button class="btn btn-default" @click.prevent="showModal('user')">
          Add user
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12" id="users">
        <table class="table">
          <tr>
            <th>Username</th>
            <th>Authorities</th>
            <th class="action"><Fa pfx="fas" ico="key" /></th>
            <th class="action"><Fa ico="trash-alt" /></th>
            <th class="action"><Fa ico="edit" /></th>
          </tr>
          <tr v-for="user in users.filter(u => u.enabled)" :key="user.name">
            <td>
              {{ user.name }}
            </td>
            <td>
              {{ user.authorities.map(a => convertRoleNames(a.authority)).join(", ") }}
            </td>
            <td>
              <a class="action" href="javascript:void(0)"
                 @click.prevent="openChangePasswordModal(user)">
                <Fa pfx="fas" ico="key" />
              </a>
            </td>
            <td>
              <a class="action text-danger" href="javascript:void(0)"
                 @click.prevent="deleteUser(user)">
                <Fa ico="trash-alt" />
              </a>
            </td>
            <td>
              <a class="action" href="javascript:void(0)"
                 @click.prevent="openUpdateUserModal(user)">
                <Fa ico="edit" />
              </a>
            </td>
          </tr>
        </table>

        <paginate
          v-model="pageNumber"
          :page-count="numPages"
          :click-handler="onPageChange"
          prev-text="Prev"
          next-text="Next"
          container-class="pagination justify-content-center"
          page-class="page-item"
          page-link-class="page-link"
          next-class="page-item"
          next-link-class="page-link"
          prev-class="page-item"
          prev-link-class="page-link"
        >
        </paginate>
      </div>
    </div>
    <modal name="user" classes="v--modal modal-container" height="auto">
      <div class="modal-header-container">
        <h1>Add user</h1>
      </div>
      <div>
        <form>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="username-create">Username</label>
            <div class="col-md-9">
              <input name="username" id="username-create" ref="input" class="form-control"
                     type="text"
                     v-model="addUser">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="role-create">Role</label>
            <div class="col-md-9">
              <select v-model="addRole" class="form-control" name="role" id="role-create">
                <option>ROLE_TA</option>
                <option>ROLE_TEACHER</option>
                <option>ROLE_ADMIN</option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex flex-row-reverse">
            <button class="btn btn-primary" type="submit" @click.prevent="addUserAction">
              Add
            </button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="user-edit" classes="v--modal modal-container" height="auto">
      <div class="modal-header-container">
        <h1>Edit user</h1>
      </div>
      <div>
        <form>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="username-edit">Username</label>
            <div class="col-md-9">
              <input name="username" id="username-edit" ref="input" class="form-control" type="text"
                     v-model="addUser" disabled>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="role-edit">Role</label>
            <div class="col-md-9">
              <select v-model="addRole" class="form-control" name="role" id="role-edit">
                <option>ROLE_TA</option>
                <option>ROLE_TEACHER</option>
                <option>ROLE_ADMIN</option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex flex-row-reverse">
            <button class="btn btn-primary" type="submit" @click.prevent="editUser">
              Edit
            </button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="user-password" classes="v--modal modal-container" height="auto">
      <div class="modal-header-container">
        <h1>Edit password</h1>
      </div>
      <div>
        <form>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="username-password">Username</label>
            <div class="col-md-9">
              <input name="username" id="username-password" ref="input" class="form-control"
                     type="text" v-model="passwordForm.username">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="old-password">Old password</label>
            <div class="col-md-9">
              <input name="oldPassword" id="old-password" ref="input" class="form-control"
                     type="password" v-model="passwordForm.oldPassword">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="username-password">New password</label>
            <div class="col-md-9">
              <input name="password" id="new-password" ref="input" class="form-control"
                     type="password" v-model="passwordForm.password">
            </div>
          </div>
          <div class="form-group d-flex flex-row-reverse">
            <button class="btn btn-primary" type="submit" @click.prevent="updatePassword">
              Update password
            </button>
          </div>
        </form>
      </div>
    </modal>
  </div>
  <Loading v-else />
</template>
<style scoped>
  th.action {
    width: 1em;
    color: #A0A0A0;
  }

  td a.action {
    color: rgb(34, 34, 34);
  }
</style>

<script>
export default {
  name: 'users',
  data() {
    return {
      loaded: true,
      users: [],
      numPages: 0,
      pageNumber: 1,
      pageSize: 25,
      addRole: '',
      addUser: '',
      passwordForm: {
        username: '',
        password: '',
        oldPassword: '',
      },
    };
  },
  mounted() {
    this.populate(+this.$route.query.page || 1, +this.$route.query.pageSize || 25);
  },
  async beforeRouteUpdate(to, _, next) {
    await this.populate(+to.query.page || 1, +to.query.pageSize || 25);
    next();
  },
  unmounted() {
    this.depopulate();
  },
  methods: {
    /**
     * Gets all users.
     */
    async populate(page, pageSize) {
      this.pageNumber = page;
      this.pageSize = pageSize;
      const users = await this.$auta.getUsers(page, pageSize);
      this.users = users.data;
      this.numPages = users.numPages;
      this.loaded = true;
    },
    depopulate() {
      this.loaded = false;
      this.users = [];
    },
    /**
     * Functoin called when the page number changes
     * @param num {number} the page number
     */
    onPageChange(num) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: { page: num, pageSize: this.pageSize },
      });
    },

    /**
     * Adds a new user and updates the current active user list.
     *
     * Creates a user with default password 'password'
     */
    async addUserAction() {
      await this.$auta.registerUser(this.addUser, 'password', this.addRole);

      this.addUser = '';
      this.addRole = '';
      this.populate();
      this.$modal.hide('user');
    },

    /**
     * Deletes a user. (actually sets enabled to false). First opens a dialog to make sure that
     * the user actually wants to delete a user.
     *
     * @param user {User} the user to add
     */
    async deleteUser(user) {
      let shouldDelete = false;
      await this.$dialog
        .confirm(`Are you sure you want to delete ${user.name}?`)
        .then(() => {
          shouldDelete = true;
        })
        .catch(() => {
          // User canceled
        });
      if (shouldDelete) {
        await this.$auta.updateUser(user.name, user.authorities[0].authority, false);
        this.populate();
      }
    },

    /**
     * Edits a user. Opens a dialog to ask if the client is sure they want to edit the user.
     */
    async editUser() {
      await this.$auta.updateUser(this.addUser, this.addRole, true);
      this.$modal.hide('user-edit');
      this.populate();
    },

    /**
     * Function called to open the updateUser modal.
     * @param user {user} the user to update.
     */
    openUpdateUserModal(user) {
      this.addUser = user.name;
      this.addRole = user.authorities[0].authority;
      this.showModal('user-edit');
    },
    /**
     * Opens the change password modal.
     * @param user {user} the user whose password will be changed.
     */
    openChangePasswordModal(user) {
      this.passwordForm.username = user.name;
      this.showModal('user-password');
    },

    /**
     * Updates a password using fields from the update password form.
     */
    async updatePassword() {
      await this.$auta.updatePassword(this.passwordForm.username, this.passwordForm.oldPassword,
        this.passwordForm.password);
      this.passwordForm = {};
      this.populate();
      this.$modal.hide('user-password');
    },

    /**
     * Shows a modal.
     *
     * @param name the name of the modal
     */
    showModal(name) {
      this.$modal.show(name);
    },
  },
};
</script>
