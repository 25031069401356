import AuthConfiguration from './AuthConfiguration.js';

/**
 * An assignment.
 */
export default class Assignment {
  /**
   * Parses an assignment from an object.
   *
   * @param obj the object to parse the assignment from
   * @param $client the client that initiated the parsing
   *
   * @returns {Assignment|null}
   */
  static parse(obj, $client) {
    if (!obj) return null;

    return new Assignment(
      obj.id,
      obj.name,
      obj.options,
      (obj.dockerAuthConfigurations || []).map(AuthConfiguration.parse),
      $client,
    );
  }

  /**
   * Creates a new assignment for uploading to the core.
   *
   * @param name the name of the new assignment
   * @param options the options of the new assignment
   * @param $client the client instance the assignment is bound to
   *
   * @returns {Assignment}
   */
  static createNew(name, options, $client) {
    return new Assignment(undefined, name, options, [], $client);
  }

  /**
   * Creates a new assignment.
   *
   * @param id the assignment's identifier
   * @param name the name of the assignment
   * @param options the metric options of the assignment
   * @param dockerAuthConfigurations the Docker authentication details
   * @param $client the client instance that requested the assignment
   */
  constructor(id, name, options, dockerAuthConfigurations, $client) {
    this.id = id;
    this.name = name;
    this.options = options;
    this.dockerAuthConfigurations = dockerAuthConfigurations;
    this.$client = $client;
  }

  /**
   * Returns a page of submissions for this assignment.
   *
   * @param page the page number of the page to retrieve
   * @param pageSize the number of submissions per page
   *
   * @returns {*|Promise<Page>}
   */
  getSubmissions(page, pageSize) {
    return this.$client.getSubmissions(this.id, page, pageSize);
  }

  /**
   * Updates this assignment on the core.
   *
   * @returns {Promise<void>}
   */
  async update() {
    await this.$client.updateAssignment(this);
  }

  /**
   * Creates this assignment on the core.
   *
   * The assignment is immediately updated with the new identifier.
   *
   * @returns {Promise<string>} the new ID
   */
  async create() {
    this.id = await this.$client.addAssignment(this);
    return this.id;
  }

  /**
   * Deletes this assignment on the core.
   *
   * @returns {Promise<void>}
   */
  async delete() {
    await this.$client.deleteAssignment(this.id);
  }

  /**
   * Serializes the assignment into an object that can be sent to the core.
   *
   * @returns {{name: *, options: *, id: *}} the serialized assignment
   */
  serialize() {
    const serialized = { name: this.name, dockerAuthConfigurations: this.dockerAuthConfigurations };
    if (this.id) serialized.id = this.id;
    Object.assign(serialized, this.options);
    return serialized;
  }
}
