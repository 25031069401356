import Entity from './Entity.js';

/**
 * A submission description.
 */
export default class Submission {
  /**
   * Unmarshalls a Submission.
   *
   * @param obj the object to parse
   * @param client the client parsing the object
   *
   * @returns {Submission} the parsed submission
   */
  static parse(obj, client) {
    return new Submission(
      obj.aid,
      obj.id,
      obj.name,
      obj.identity,
      obj.verdict,
      Entity.parse(obj.results),
      obj.pipelineLog,
      obj.canceled,
      obj.exception,
      client,
    );
  }

  /**
   * Creates a new submission.
   *
   * @param aid the identifier of the assignment the submission was for
   * @param id the identifier
   * @param name the name
   * @param {String} identity the identity string
   * @param verdict the verdict
   * @param entity the results entity
   * @param pipelineLog the pipeline log
   * @param canceled whether the submission's job was canceled
   * @param exception the exception caused by the submission, if any
   * @param $client the user's AuTA client
   */
  constructor(
    aid, id, name, identity, verdict, entity, pipelineLog, canceled, exception, $client,
  ) {
    this.aid = aid;
    this.id = id;
    this.name = name;
    this.identity = identity ? identity.identifier : null;
    this.verdict = verdict;
    this.entity = entity;
    this.pipelineLog = pipelineLog;
    this.canceled = canceled;
    this.exception = exception;
    this.$client = $client;
  }

  /**
   * Returns the submission's verdict.
   *
   * @returns {Promise<string>}
   */
  async getVerdict(getCached) {
    if (getCached && this.verdict) return this.verdict;
    return this.verdict = await this.$client.getVerdict(this.aid, this.id);
  }

  /**
   * Returns the submission's entity tree.
   *
   * @param getCached if {@code true}, the the submission may return its cached values
   *                  instead of requesting new data from the server
   *
   * @returns {Promise<Submission.entity>}
   */
  async getEntity(getCached) {
    if (getCached && this.entity) return this.entity;
    const sub = await this.$client.getSubmission(this.aid, this.id);
    this.entity = sub.entity;
    return this.entity;
  }
}
