<template>
  <div v-if="loaded">
    <div class="row">
      <div class="col-12" id="breadcrumbs">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'assignments' }">Assignments</router-link>
          </li>
          <li v-if="action === 'edit'" class="breadcrumb-item">
            <router-link :to="{ name: 'assignment', params: { aid: assignment.id } }">
              {{ assignment.name }}
            </router-link>
          </li>
          <li v-if="action === 'edit'" class="breadcrumb-item">{{ action }}</li>
          <li v-if="action !== 'edit'" class="breadcrumb-item">New</li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col-12" id="assignment">
        <form>
          <b-tabs>
            <b-tab title="Basic">
              <div class="form-group row">
                <label class="col-md-2 col-form-label" for="name">Name</label>
                <div class="col-md-10">
                  <input type="text" id="name" name="name" v-model="assignment.name"
                         class="form-control" />
                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label" for="language">Language</label>
                <div class="col-md-10">
                  <select class="form-control"
                          ref="language"
                          id="language"
                          @change="onLanguageChange"
                  >
                    <option v-for="language in languages" :value="language.id"
                            :key="`lang-${language.id}`"
                            ref="languageSelector"
                            :selected="language.id === assignment.options.language"
                    >
                      {{ language.title }}
                    </option>
                  </select>
                </div>
              </div>
            </b-tab>
            <b-tab title="Advanced">
              <h5 v-if="action !== 'edit'">
                Use custom metrics
                <input type="checkbox" id="checkbox"
                       ref="advancedCheck" v-model="assignment.advancedMetrics"
                >
              </h5>
              <div class="metrics-editor" v-if="assignment.advancedMetrics || action === 'edit'">
                <h4>Metrics</h4>
                <div v-show="loaded" class="metrics list-group">
                  <!-- eslint-disable-next-line max-len -->
                  <MetricEditor v-for="metricSettings in assignment.options.static"
                                :assignment="assignment"
                                :metric="findMetricByName(metricSettings.metric)"
                                :settings="metricSettings"
                                :metrics="assignment.options.static"
                                :key="`metric-editor-${metricSettings.metric}`"
                                v-on:remove-metric="removeMetric"
                                ref="metric-editors"
                  />
                </div>
                <div>
                  <select class="form-control" @change="addMetric" ref="metricSelector">
                    <option value="?">Add new metric...</option>
                    <!-- eslint-disable-next-line max-len -->
                    <option v-for="metric in filterByNotAdded(metricsForLanguage)"
                            :value="metric.internal_name"
                            :key="`new/${metric.languages.join(';')}/${metric.internal_name}`">
                      {{ beautifyMetricName(metric.name) }} [{{ metric.languages.join(', ') }}]
                    </option>
                  </select>
                </div>
              </div>
            </b-tab>
          </b-tabs>
          <div class="form-action-row action-row">
            <button type="button" class="btn btn-primary" @click="submit(false)">
              {{ action === 'edit' ? 'Update' : 'Create' }}
            </button>
            <span v-if="invalidMetrics.length > 0">
              The following metrics are configured incorrectly: {{ invalidMetrics.join(', ') }}.
              <button type="button" class="btn btn-danger" @click="submit(true)">
                {{ action === 'edit' ? 'Update' : 'Create' }} anyway
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Loading v-else />
</template>

<script>
export default {
  name: 'assignment-editor',
  data() {
    return {
      assignment: {
        id: undefined,
        name: undefined,
        advancedMetrics: false,
        options: {
          static: [],
          language: 'auto',
        },
      },
      action: 'new',
      // If the 'create new assignment button is pressed on a course page, a query parameter
      // ?cid=... will be added to the route
      forCourse: undefined,
      metrics: [],
      metricsForLanguage: [],
      loaded: false,
      languages: [
        { id: 'auto', title: 'Autodetect' },
        { id: 'java', title: 'Java' },
        { id: 'python', title: 'Python' },
        { id: 'x86_64', title: 'x86-64 assembly' },
        { id: 'scala', title: 'Scala' },
        { id: 'c', title: 'C' },
        { id: 'cpp', title: 'C++' },
      ],
      invalidMetrics: [],
    };
  },
  mounted() {
    this.populate();
  },
  async beforeRouteUpdate(to, from, next) {
    await this.populate();
    next();
  },
  methods: {
    async populate() {
      this.loaded = false;

      this.metrics = await this.$auta.getMetrics();

      if (this.$route.params.aid) {
        this.assignment = await this.$auta.getAssignment(this.$route.params.aid);
        // Remove any metrics that no longer exist in the worker from the assignment.
        this.assignment.options.static = this.assignment.options.static
          .filter(m => m && this.findMetricByName(m.metric));
        this.updateAvailableMetrics();
      }

      this.action = this.$route.params.action || 'new';
      this.forCourse = this.$route.query.cid;

      this.loaded = true;
    },
    depopulate() {
      this.loaded = false;
      this.assignment = this.data().assignment;
    },
    /**
     * Filters the list of metrics by those that have not yet been added to the assignment.
     *
     * @param metricsList {object<{internal_name: String,
     *          languages: String[], name: String, script_presets: object[]}>} the list of
     *          metrics that are available
     */
    filterByNotAdded(metricsList) {
      const alreadyAdded = this.assignment.options.static.map(m => m.metric);
      return metricsList.filter(m => !alreadyAdded.includes(m.internal_name));
    },
    /**
     * Removes a metric from the this.assignment.options object.
     * @param {object<{metric: String}>} settings
     */
    removeMetric(settings) {
      const metricName = settings.metric;
      this.assignment.options.static = this.assignment.options.static
        .filter(s => s.metric !== metricName);
    },
    /**
     * Adds a metric to the list of metrics in this.assignment.options.static
     */
    addMetric() {
      const select = this.$refs.metricSelector;
      this.assignment.options.static.push({
        metric: select.value,
      });
    },
    /**
     * Function called when the language of an assignment is changed.
     */
    onLanguageChange() {
      this.assignment.options.language = this.$refs.language.value;
      this.updateAvailableMetrics();
    },
    /**
     * Gets the list of metrics available for this assingment's language, and sorts them
     * alphabetically.
     */
    updateAvailableMetrics() {
      this.metricsForLanguage = this.metrics
        .filter(m => m.languages.indexOf(this.assignment.options.language) !== -1
             || m.languages.length === 0 || this.assignment.options.language === 'auto')
        .sort((x, y) => x.name.localeCompare(y.name, 'en', { ignorePunctuation: true }));
    },
    findMetricByName(name) {
      return this.metrics.find(m => m.internal_name === name);
    },
    /**
     * Submits the assignment settings to the server.
     *
     * @param force if true,
     *
     * @returns {Promise<void>}
     */
    async submit(force = false) {
      const isEditAction = this.action === 'edit';

      // Validate all metric settings
      this.invalidMetrics = [];
      if (this.$refs['metric-editors']) {
        // eslint-disable-next-line no-restricted-syntax
        for (const editor of this.$refs['metric-editors']) {
          // eslint-disable-next-line no-await-in-loop
          if ((await editor.validate()) !== true) {
            this.invalidMetrics.push(editor.beautifiedName);
          }
        }
      }

      if (!force && this.invalidMetrics.length > 0) {
        return;
      }

      /* Create an options object which will be sent to the server. If the assignment is new (or is
      a clone of another assignment) and advanced metrics was not selected, select all metrics that
      have a preset script. Otherwise use metrics added to the editors. */
      const newOptions = {
        language: this.$refs.language.value,
        static: [], // by default, select no metrics
        'assignment name': this.assignment.name,
      };

      // if the 'advanced' option is selected, or the action is equal to 'edit', the metrics and
      // scripts that have been selected need to be added to the assignment.
      if (isEditAction || this.assignment.advancedMetrics) {
        const newMetrics = [];
        // selecting no metrics is allowed. However, this ref is undefined if no metrics are
        // selected, which is why we need to check for this.
        if (this.$refs['metric-editors']) {
          // eslint-disable-next-line no-restricted-syntax
          for (const editor of this.$refs['metric-editors']) {
            const settings = editor.extractSettings();
            this.assignment.dockerAuthConfigurations = editor.extractDockerAuthConfigurations();
            newMetrics.push(settings);
          }
        }
        newOptions.static = newMetrics;
      } else {
        // select all metrics if the assignment type is 'new' or 'clone' and the advanced metrics
        // option was not selected.
        newOptions.static = this.getBasicMetrics();
      }
      Object.freeze(newOptions);

      // Create new assignment if clone or new, otherwise update the old assignment.
      let aid;
      if (isEditAction) {
        // update the assignment if editing
        const sa = this.assignment.serialize();
        const a = this.$auta.createNewAssignment(sa.name, newOptions);
        a.dockerAuthConfigurations = this.assignment.dockerAuthConfigurations;
        a.id = sa.id;
        aid = a.id;
        await this.$auta.updateAssignment(a);
      } else {
        // else create a new assignment
        const a = this.$auta.createNewAssignment(this.assignment.name, newOptions);
        a.dockerAuthConfigurations = this.assignment.dockerAuthConfigurations;
        aid = await this.$auta.addAssignment(a);
      }

      if (this.forCourse) {
        this.redirectToCourse(aid, this.forCourse);
        return;
      }

      this.$router.push({ name: 'assignment', params: { aid } });
    },

    /**
     * Adds the new assignment to the course, and then redirects the page to the course's page.
     *
     * @param aid {string} the id of the newly created assignment
     * @param cid {string} the id of the course that will be navigated to
     * @returns void
     */
    async redirectToCourse(aid, cid) {
      await this.$auta.addAssignmentToCourse({ id: cid }, aid);
      this.$router.push({ name: 'course', params: { cid } });
    },

    /**
     * Gets a list of all metrics that have preset scripts.
     *
     * @returns {object<{name:String, script:String, formatter:String, options: object}>}}
     */
    getBasicMetrics() {
      return this.metricsForLanguage
        .filter(metric => metric.script_presets)
        .map((metric) => {
          const settings = {
            name: metric.internal_name,
            script: metric.script_presets[0].script,
            formatter: '',
            options: {},
          };
          return Object.freeze(settings);
        });
    },
  },
};
</script>

<style scoped>
.action-row {
  margin-top: 15px;
}
</style>
