<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <div class="container">
        <router-link class="navbar-brand" :to="{ name: 'courses' }">
          <img src="/img/auta-50x50.png" width="30" height="30" alt="" />
          AuTA
        </router-link>
        <!-- eslint-disable-next-line max-len -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" v-for="nav in navbar" :key="nav.title">
              <router-link class="nav-link" :to="nav.link">{{ nav.title }}</router-link>
            </li>
          </ul>
        </div>

        <div>
          <span v-if="username" class="navbar-text" id="user-welcome-message">
            Welcome, <strong>{{ username }}</strong>
          </span>
          <button v-if="username" @click="signOut" class="btn btn-default" id="sign-out">
            Sign out
          </button>
          <button v-else @click="signIn" class="btn btn-success" id="sign-in">
            Sign in
          </button>
        </div>
      </div>
    </nav>
    <div class="container">
      <router-view @error="showError"/>
    </div>
    <modal name="login" classes="v--modal login-container" height="auto">
      <div class="login-header-container">
        <h1>Sign in</h1>
      </div>
      <div>
        <form>
          <div class="form-group">
            <label for="username">Username</label>
            <input class="form-control" name="username" id="username" type="text" required>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input class="form-control" name="password" id="password" type="password" required>
          </div>
          <div class="submit-group">
            <button type="submit" class="btn btn-primary" @click.prevent="onLogin">Submit</button>
            <ul class="list-group list-group-horizontal">
              <li v-for="error in login.errors" :key="`${error.code}:${error.message}`"
                  class="list-group-item list-group-item-danger">
                <strong>{{ error.message }}</strong>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </modal>
    <modal name="error" classes="v--modal" height="auto">
      <div class="modal-header modal-confirm justify-content-center">
          <Fa pfx="far" ico="times-circle"></Fa>
      </div>
      <div class="modal-body text-center">
        <h4 class="mb-4">Something went wrong!</h4>
        <p class="font-weight-bold">{{ prettyErrorCode(error.code) }}
        </p>
        <p>{{ error.message }}</p>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: {
        errors: [],
      },
      error: {
        code: undefined,
        message: undefined,
      },
      username: undefined,
    };
  },
  created() {
    this.connect();
  },
  methods: {
    showError(error) {
      if (error && error.data && error.data.errors) {
        // eslint-disable-next-line prefer-destructuring
        this.error = error.data.errors[0];
      } else if (error && error.data && error.data.error) {
        this.error = { code: error.data.error, message: error.data.message };
      } else {
        this.error = { code: 'UNKOWN', message: 'UNKNOWN' };
      }
      /* eslint-disable no-console */
      console.warn('Error occurred');
      console.warn(error);
      this.$modal.show('error');
    },
    /**
     * Converts an error code to a more readable format.
     * @param {string} code the error code to prettify
     * @returns {string}
     */
    prettyErrorCode(code) {
      if (code) {
        return code.split('_').map(part => this.capitalize(part)).join(' ');
      }
      return 'UNKNOWN';
    },
    signIn() {
      this.$modal.show('login');
    },
    async signOut() {
      await this.$auta.logout();
      this.$router.go();
    },
    async onLogin() {
      const username = document.getElementById('username').value;
      const password = document.getElementById('password').value;

      this.$auta.login(username, password).then(() => this.$router.go()).catch((err) => {
        const res = err;
        this.login.errors = res.data.errors;
      });
    },
    async connect() {
      try {
        await this.$auta.connect();
        this.username = this.$auta.user.name;
      } catch (ex) {
        // Discard
      }
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: 'DejaVu Sans', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

code {
  color: #333;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#user-welcome-message {
  margin-right: 1.2em;
}

.auth-errors {
  margin-top: .8rem;
}

.navbar {
  font-size: 0.9rem;
  font-weight: 400;
}

.nav-link {
  color: rgba(100%, 100%, 100%, 0.85);
}

.login-container, .modal-container {
  padding: 12px 24px 24px 24px !important;

  .list-group-item {
    padding: 0.5rem 1.25rem;
  }
}

.login-header-container, .modal-header-container {
  width: 100%;

  display: flex;

  justify-content: space-between;

  margin-bottom: 12px;
}

.submit-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
}

.modal-confirm {
  background: #ff4b55;
  font-size: 50px;
  color: #efefef;
  padding: 30px 0px;
}

</style>
