<template>
  <div>
    <div class="row">
      <div class="col-12" id="breadcrumbs">
        <ol class="breadcrumb" v-if="submission.id"> <!-- Prevents warnings that sid is missing-->
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'assignments' }">Assignments</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'assignment', params: { aid: assignment.id } }">
              {{ assignment.name }}
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'submission', params: { sid: submission.id } }">
              {{ submission.name }}
            </router-link>
          </li>
        </ol>
      </div>
    </div>
    <div class="page-header">
      <div class="row">
        <div class="col-8">
          <h1>Benchmarking</h1>
        </div>
        <div class="col-4">
          <div class="card shadow position-absolute">
            <div class="card-header">
              <button class="btn btn-link btn-block" v-on:click="collapsed = !collapsed">
                {{ chartData.metricName }}
                <Fa class="float-right" pfx="fas" :ico="`chevron-${!collapsed ? 'up' : 'down'}`" />
              </button>
            </div>
            <div class="card-body" id="metric-selector" ref="metric-selector" v-show="!collapsed">
              <form>
                <div class="form-group">
                  <label for="metricName">Metric name</label>
                  <select name="assignmentList" v-model="formData.metric.name" required
                          id="metricName" class="form-control">
                    <option disabled value="">Please select one</option>
                    <option v-for="metric in metrics" v-bind:value="metric" v-bind:key="metric">
                      {{ beautifyMetricName(metric) }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="entityLevel">Entity level</label>
                  <select name="entityLevel" v-model="formData.level" required id="entityLevel"
                          class="form-control">
                    <option disabled value="">Please select one</option>
                    <option>METHOD</option>
                    <option>CLASS</option>
                    <option>FILE</option>
                  </select>
                </div>
                <button class="btn btn-primary" @click.prevent="fetchChartData">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" id="benchmark">
      <canvas id="canvas" ref="canvas"></canvas>
    </div>
    <div class="row" id="list-area">
      <ul v-show="barClickEntities.length > 0" id="entity-list" ref="entityList" class="list-group">
        <li class="list-group-item" v-for="entityName in barClickEntities" :key="entityName"
            v-html="boldEntityName(entityName)">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'benchmarking',
  data() {
    return {
      // Whether the card is collapsed.
      collapsed: true,
      // data in the metrics selection form
      formData: {
        metric: {
          name: undefined,
          value: undefined,
        },
        level: 'METHOD',
      },
      // the chart.js component
      chart: undefined,
      // list of metrics associated with current assignment
      metrics: undefined,
      // data associated with chart (as fetched from core)
      chartData: {
        dataset: undefined,
        labels: undefined,
        raw: undefined,
        metricName: undefined,
        entityLevel: undefined,
        entities: undefined,
      },
      // the entity names associated with the bar that was clicked on in the chart
      barClickEntities: [],
      // the current submission
      submission: {
        name: undefined,
        verdict: undefined,
        uml: undefined,
      },
      // the current assignment
      assignment: {
        id: undefined,
      },
    };
  },
  created() {
    this.fetchSubmission();
  },
  methods: {
    /**
     * Fetches a submission. The try-catch block is required, as otherwise the global error
     * handler will not catch the error.
     */
    fetchSubmission() {
      (async () => {
        try {
          const { aid, sid } = this.$route.params;
          this.$auta.getAssignment(aid)
            .then((a) => {
              this.assignment = a;
              this.metrics = this.getMetrics(a);

              // If no metric has been selected, select the first in the list.
              if (this.metrics.length > 0 && !this.formData.metric.name) {
                // eslint-disable-next-line prefer-destructuring
                this.formData.metric.name = this.metrics[0];
              }
            });
          this.submission = await this.$auta.getSubmission(aid, sid);
          this.fetchChartData();
        } catch (e) {
          this.$emit('error', e);
          throw e;
        }
      })();
    },
    /**
     * Gets the metrics from an assignment.
     *
     * @param assignment {object}
     * @returns {*}
     */
    getMetrics(assignment) {
      return assignment.options.static.map(e => e.metric);
    },

    /**
     * Get the chart data required for the chart. Called when the submit button is pressed.
     * @returns {Promise<void>}
     */
    async fetchChartData() {
      const response = await this.$auta.getBenchmarkingChartData(this.submission,
        this.formData.metric,
        this.formData.level);
      this.chartData = response;
      const data = this.getChartData(this.chartData.labels, this.chartData.dataset,
        this.chartData.metricName);
      const options = this.getChartOptions();
      this.loadChart(data, options);
      this.collapsed = true;
    },

    /**
     * Show the entity names that correspond to the bar that is clicked.
     *
     * This is done by finding the label that corresponds to the clicked bar, looking up which
     * entities have this label (metric value) and then appending them to a list.
     *
     * @param event {Object} the event that triggered the function
     * @param array {Object[]} an array of active elements that were clicked on.
     */
    showEntityNames(event, array) {
      this.barClickEntities = [];
      if (array.length > 0) {
        // eslint-disable-next-line no-underscore-dangle
        const label = this.chart.data.labels[array[0]._index];
        if (Object.prototype.hasOwnProperty.call(this.chartData.entities, `${label}`)) {
          this.barClickEntities = this.chartData.entities[`${label}`];
        }
      }

      // Needs to run on the next tick, otherwise this will execute before the list renders.
      this.$nextTick(() => this.$refs.entityList.scrollIntoView({ behavior: 'smooth' }));
    },

    /**
     * Gets the data object that will be passed to the chart.
     *
     * @param labels {string[]} the labels that will be placed below each bar. Correspond to
     * metric values
     * @param dataset {number[]} the height of the bars
     * @param metricName the name that will be displayed above the graph
     * @returns {object} the data object that chartjs requires
     */
    getChartData(labels, dataset, metricName) {
      return {
        labels,
        datasets: [{
          label: metricName,
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgb(255, 99, 132)',
          data: dataset,
        }],
      };
    },

    /**
     * Get the options that the chart requires to render. This includes a reference to the
     * function that will be called when someone clicks on a bar.
     *
     * @returns {object} the options that will be passed to the chart.
     */
    getChartOptions() {
      const fn = this.showEntityNames;
      return {
        onClick(event, array) {
          fn(event, array);
        },
        responsive: true,
        title: {
          display: true,
          text: 'Code static analysis results',
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Metric value',
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Relative weight of code (% of total system weight)',
            },
          }],
        },
      };
    },

    /**
     * Makes an entity name, which /looks/like/this(string hi) bold.
     *
     * Formats the name after the last / with strong tags.
     *
     * @param name {string} the entity name
     * @returns {string} the formatted entity name
     */
    boldEntityName(name) {
      const split = name.split('/');
      if (split.length <= 1) {
        return `<strong>${name}</strong>`;
      }
      return `${split.slice(0, -1).join('/')}/<strong>${split[split.length - 1]}</strong>`;
    },

    /**
     * Loads the chart onto the page.
     *
     * Populates the 'canvas' element.
     *
     * @param data {object} the data field
     * @param options {object} the options object
     */
    loadChart(data, options) {
      if (this.chart !== undefined) {
        this.chart.destroy();
      }
      const ctx = this.$refs.canvas.getContext('2d');
      // eslint-disable-next-line no-undef
      this.chart = new Chart(ctx, {
        type: 'bar',
        data,
        options,
      });
    },
  },

};
</script>

<style scoped>
  .card {
    left: 15px;
    right: 15px;
  }
</style>
