<template>
  <div v-if="loaded">
    <div class="row">
      <div class="col-12" id="breadcrumbs">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'courses' }">Courses</router-link>
          </li>
          <li v-if="action === 'edit'" class="breadcrumb-item">
            <router-link :to="{ name: 'course', params: { cid: course.id } }">
              {{ `${course.name}:${course.courseCode} - ${course.year}` }}
            </router-link>
          </li>
          <li v-if="action === 'edit'" class="breadcrumb-item">{{ action }}</li>
          <li v-if="action !== 'edit'" class="breadcrumb-item">New</li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col-12" id="course">
        <form>
          <div class="form-group row">
            <label class="col-md-2 col-form-label" for="name">Name</label>
            <div class="col-md-10">
              <input type="text" id="name" name="name" v-model="course.name"
                     class="form-control" />
              <div class="invalid-feedback"></div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label" for="courseCode">Course code</label>
            <div class="col-md-10">
              <input type="text" id="courseCode" name="courseCode" v-model="course.courseCode"
                     class="form-control" />
              <div class="invalid-feedback"></div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label" for="year">Year</label>
            <div class="col-md-10">
              <input type="number" min="2000" id="year" name="year" v-model="course.year"
                     class="form-control" />
              <div class="invalid-feedback"></div>
            </div>
          </div>
          <div class="form-action-row">
            <div class="col-md-1">
              <button type="button" class="btn btn-primary" @click="submit">
                {{ action === 'edit' ? 'Update' : 'Create' }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Loading v-else />
</template>

<script>
import Course from '../model/Course.js';

export default {
  name: 'course-editor',
  data() {
    return {
      course: {
        id: undefined,
        name: undefined,
        courseCode: undefined,
        year: undefined,
        taSet: undefined,
        instructorSet: undefined,
        assignmentIds: undefined,
      },
      action: this.$route.params.action || 'new',
      loaded: false,
    };
  },
  mounted() {
    this.populate(this.$route.params.cid);
  },
  async beforeRouteUpdate(to, from, next) {
    await this.populate(to.params.cid);
    next();
  },
  unmounted() {
    this.loaded = false;
    this.course = this.data().course;
  },
  methods: {
    async populate(cid) {
      this.loaded = false;
      if (cid) {
        this.course = await this.$auta.getCourse(cid);
      }
      this.loaded = true;
    },
    async submit() {
      if (this.action === 'new' || this.action === 'clone') {
        const newCourse = Course.createNew(this.course.name, this.course.courseCode,
          this.course.year, this.$auta);
        const id = await this.$auta.addCourse(newCourse);
        this.$router.push({ name: 'course', params: { cid: id } });
      } else {
        await this.$auta.updateCourse(this.course);
        this.$router.push({ name: 'course', params: { cid: this.course.id } });
      }
    },
  },
};
</script>
