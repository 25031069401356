<template>
  <div class="row">
    <div class="col-12 py-md-3">
      <div class="btn-toolbar justify-content-between" role="toolbar"
           aria-label="Worker operations">
        <a href="#" @click.prevent="1 + 1">
          <button type="button" class="btn btn-primary" @click="genToken">
            <i class="fas fa-plus"></i>
            New token
          </button>
        </a>
        <div class="input-group">
          <label for="search" class="sr-only">Search</label>
          <input type="search" id="search" name="search" placeholder="Search" />
        </div>
      </div>

      <div id="workers">
        <table class="table">
          <tr>
            <th class="with-explanation"
                title="Only for quick identification. Names do not carry any other information.">
              Name
            </th>
            <th>Last seen</th>
            <th>Status</th>
            <th class="worker-action">
              <span title="View logs"><Fa pfx="fas" ico="file-alt" /></span>
            </th>
            <th class="worker-action">
              <span title="Cancel job"><Fa pfx="fas" ico="ban" /></span>
            </th>
            <th class="worker-action">
              <span title="Kill"><Fa pfx="fas" ico="skull-crossbones" /></span>
            </th>
          </tr>
          <tr v-for="worker in workers" :key="worker.getUniversalIdentifier()">
            <td>
              {{ worker.name }}
            </td>
            <td>
              <span :title="moment(worker.lastPing, 'x').format()">
                {{ moment(worker.lastPing, 'x') }}
              </span>
            </td>
            <td>
              <!-- eslint-disable-next-line max-len -->
              <router-link v-if="worker.job" :to="{ name: 'submission', params: { aid: worker.job.assignment, sid: worker.job.submission } }">
                Busy
              </router-link>
              <span v-if="!worker.job">Idle</span>
            </td>
            <td>
              <a class="action" href="#" @click.prevent="getLogs(worker)" title="View logs">
                <Fa pfx="fas" ico="file-alt" />
              </a>
            </td>
            <td>
              <a class="action text-danger" href="#" @click.prevent="confirmCancelWorkerJob(worker)"
                 title="Cancel job"
              >
                <Fa pfx="fas" ico="ban"/>
              </a>
            </td>
            <td>
              <a class="action text-danger" href="#" @click.prevent="confirmKillWorker(worker)"
                 title="Kill"
              >
                <Fa pfx="fas" ico="skull-crossbones" />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <modal name="token" classes="v--modal modal-container" height="auto" @closed="clearToken">
      <div class="modal-header-container">
        <h1>New worker token</h1>
      </div>
      <div>
        <pre class="token" ref="token" @click="selectToken">{{ generatedToken || '' }}</pre>
        <p>
          <strong>
            Store this token somewhere secure. You will not be able to view this token again.
          </strong>
        </p>
      </div>
    </modal>
    <modal name="cancelJob" classes="v--modal modal-container" height="auto"
           @closed="clearCancelJobModal">
      <div class="modal-header-container">
        <h1>Cancel job</h1>
      </div>
      <div v-if="cancelJobModal.loaded && cancelJobModal.submission">
        <div>
          <p>
            Are you sure you want to stop the following job:
          </p>
          <dl>
            <dt>Assignment</dt>
            <!-- eslint-disable-next-line max-len -->
            <dd><router-link :to="{ name: 'assignment', params: { aid: cancelJobModal.worker.job.assignment } }">
              {{ cancelJobModal.assignment.name }}
            </router-link></dd>
            <dt>Submission</dt>
            <!-- eslint-disable-next-line max-len -->
            <dd><router-link :to="{ name: 'submission', params: { aid: cancelJobModal.worker.job.assignment, sid: cancelJobModal.worker.job.submission } }">
              {{ cancelJobModal.submission.name }}
            </router-link></dd>
          </dl>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" @click="cancelWorkerJob()">Stop job</button>
          <button type="button" class="btn btn-primary" @click="$modal.hide('cancelJob')">
            Keep job
          </button>
        </div>
      </div>
      <div v-else-if="cancelJobModal.loaded">
        <div>
          <p>
            This worker is idle.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="$modal.hide('cancelJob')">
            Close
          </button>
        </div>
      </div>
      <Loading v-else />
    </modal>
    <modal name="killWorker" classes="v--modal modal-container" height="auto"
           @closed="clearKillWorkerModal">
      <div class="modal-header-container">
        <h1>Kill worker</h1>
      </div>
      <div v-if="killWorkerModal.loaded">
        <div>
          <p>
            Are you sure you want to kill worker {{ killWorkerModal.worker.name }}?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" @click="killWorker()">Kill</button>
          <button type="button" class="btn btn-primary" @click="$modal.hide('killWorker')">
            Cancel
          </button>
        </div>
      </div>
      <Loading v-else />
    </modal>
    <modal name="workerLogs" classes="v--modal modal-container" height="90%" width="80%" :adaptive="true"
           @closed="clearWorkerLogsModal">
      <div class="modal-header-container">
        <h1 v-if="workerLogsModal.loaded">Logs for {{ workerLogsModal.worker.name }}</h1>
      </div>
      <div v-if="workerLogsModal.loaded" style="height: 80%">
        <div style="height: 100%">
          <pre v-html="workerLogsModal.logs" class="logs-container">
          </pre>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="$modal.hide('workerLogs')">
            Close
          </button>
        </div>
      </div>
      <Loading v-else />
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import AnsiConverter from 'ansi-to-html';
import AutaWorker from '../model/Worker.js';

export default {
  name: 'workers',
  data() {
    return {
      workers: [],
      moment,
      generatedToken: undefined,
      updateHandle: undefined,
      cancelJobModal: {
        assignment: undefined,
        submission: undefined,
        loaded: false,
      },
      killWorkerModal: {
        worker: undefined,
        loaded: false,
      },
      workerLogsModal: {
        worker: undefined,
        logs: '',
        loaded: false,
      },
    };
  },
  created() {
    this.fetchWorkers();
  },
  async mounted() {
    const { http } = this.$auta;
    const { token } = await http.authProvider.getToken();

    this.eventSource = new EventSource(
      `${http.host}/api/v1/worker/events?auth-token=${token}`,
      { withCredentials: true },
    );

    const updateWorkerInfoFromEvent = ev => {
      const newWorker = AutaWorker.parse(JSON.parse(ev.data));

      const oldIdx = this.workers.findIndex(w => w.name === newWorker.name);
      if (oldIdx === -1) {
        this.workers.push(newWorker);
      } else {
        this.$set(this.workers, oldIdx, newWorker);
      }
    };

    const eventTypes = ['worker-ping-response', 'job-assigned-to-worker'];
    for (const eventType of eventTypes) {
      this.eventSource.addEventListener(eventType, updateWorkerInfoFromEvent);
    }

    this.eventSource.addEventListener('worker-left', ev => {
      const worker = AutaWorker.parse(JSON.parse(ev.data));
      const oldIdx = this.workers.findIndex(w => w.name === worker.name);
      this.workers.splice(oldIdx, 1);
    });
  },
  unmounted() {
    this.eventSource.close();
  },
  beforeDestroy() {
    this.eventSource.close();
  },
  methods: {
    async fetchWorkers() {
      this.workers = await this.$auta.getWorkers();
      this.loading = false;
    },
    async genToken() {
      this.generatedToken = await this.$auta.getWorkerToken();
      this.$modal.show('token');
    },
    clearToken() {
      this.generatedToken = undefined;
    },
    selectToken() {
      const range = document.createRange();
      range.selectNode(this.$refs.token);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    },
    async confirmCancelWorkerJob(worker) {
      this.$modal.show('cancelJob');
      this.cancelJobModal.worker = worker;
      if (worker.job) {
        this.cancelJobModal.assignment = await this.$auta.getAssignment(worker.job.assignment);
        this.cancelJobModal.submission = await this.$auta.getSubmission(
          worker.job.assignment, worker.job.submission,
        );
      }
      this.cancelJobModal.loaded = true;
    },
    clearCancelJobModal() {
      this.cancelJobModal.loaded = false;
      this.cancelJobModal.assignment = undefined;
      this.cancelJobModal.submission = undefined;
    },
    async cancelWorkerJob() {
      const { worker, submission } = this.cancelJobModal;
      await this.$auta.workers.cancelJob(worker, submission.id);
      this.$modal.hide('cancelJob');
    },
    async confirmKillWorker(worker) {
      this.$modal.show('killWorker');
      this.killWorkerModal.worker = worker;
      this.killWorkerModal.loaded = true;
    },
    clearKillWorkerModal() {
      this.killWorkerModal.loaded = false;
      this.killWorkerModal.worker = undefined;
    },
    async killWorker() {
      const { worker } = this.killWorkerModal;
      await this.$auta.workers.kill(worker);
      this.$modal.hide('killWorker');
    },
    async getLogs(worker) {
      this.workerLogsModal.worker = worker;
      this.$modal.show('workerLogs');
      const logArr = await this.$auta.workers.getLogs(worker);
      const ansiConverter = new AnsiConverter();
      this.workerLogsModal.logs = ansiConverter.toHtml(logArr.join('\n'));
      this.workerLogsModal.loaded = true;
    },
    clearWorkerLogsModal() {
      this.workerLogsModal.loaded = false;
      this.workerLogsModal.worker = undefined;
      this.workerLogsModal.logs = '';
    },
  },
};
</script>

<style lang="less">
pre.token {
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: #fae2e2;
  padding: .5em;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 1px lightgray;
}

.with-explanation {
  text-decoration: underline dotted;
}

.worker-action {
  width: 1%;
}

.logs-container {
  height: 100%;
  overflow: scroll;
}
</style>
