import axios from 'axios';
import Http from './Http.js';

export default class AxiosHttp extends Http {
  /* eslint-disable no-underscore-dangle,implicit-arrow-linebreak,comma-dangle,function-paren-newline */

  /**
   * Creates a new AjaxHttp instance.
   *
   * @param base the base URL all requests should be performed against
   * @param authProvider the authentication provider to get authentication tokens from
   * @param debug if true, enables detailed logging for all requests
   */
  constructor(base, authProvider, debug) {
    super();
    this.host = base;
    this.authProvider = authProvider;
    this.debug = debug;
    this._axios = axios.create();
  }

  static _deepFreeze(object) {
    for (const name of Object.getOwnPropertyNames(object)) {
      const value = object[name];

      if (value && typeof value === 'object') {
        AxiosHttp._deepFreeze(value);
      }
    }

    return Object.freeze(object);
  }

  /**
   * Performs the request.
   *
   * @param method the request method
   * @param url the url the request is against
   * @param body the request body, if any
   * @param resolve the callback to call on success
   * @param reject the callback to call on failure
   * @param token the authentication token or undefined to skip authentication for this request
   * @param bodyType the datatype of the body
   *
   * @private
   */
  _performRequest(method, url, body, resolve, reject, token, bodyType) {
    /* eslint-disable no-console */
    const headers = {};
    if (bodyType) {
      headers['Content-Type'] = bodyType;
    }
    if (token) {
      headers.Authorization = `AutaToken ${token}`;
    }

    this._axios.request({
      url,
      method,
      baseURL: this.host,
      withCredentials: token,
      headers,
      data: body,
    }).then((res) => {
      // AxiosHttp._deepFreeze(res.data);
      resolve(this.reply(res.data, res));
    }).catch((err) => {
      if (err.response) {
        if (this.debug) console.error(err.response.data);
        reject(this.reply(err.response.data, err.response));
      } else if (err.request) {
        if (this.debug) console.error(err.request);
        reject(this.reply(
          { status: 0, error: 'No response', message: 'The server did not respond' },
          { status: 0 },
        ));
      } else {
        if (this.debug) console.error(err.message);
        reject(this.reply(
          { status: 0, error: 'Request failed', message: err.message },
          { status: 0 },
        ));
      }
    });

    this.log(`${token ? 'AUTH' : 'ANON'} ${method} ${url}${body ? ' +body' : ''}`);
  }

  /**
   * Performs a request.
   *
   * @param method the HTTP request method
   * @param url the URL to perform the request against
   * @param body the request body, if any
   * @param unauthenticated if true, no authentication token is attached (useful for /login calls)
   * @param bodyType the datatype of the body
   *
   * @returns {Promise<Object>} a promise containing the response to the request
   */
  request(method, url, body, unauthenticated, bodyType = Http.JSON_MIME) {
    return new Promise((resolve, reject) => {
      if (unauthenticated) {
        this._performRequest(method, url, body, resolve, reject, false, bodyType);
      } else {
        this.authProvider.getToken().then((token) => {
          const tokenStr = token && token.token ? token.token : undefined;
          this._performRequest(method, url, body, resolve, reject, tokenStr, bodyType);
        }).catch(reject);
      }
    });
  }

  log(msg) {
    if (!this.debug) return;

    console.log(`XHR DEBUG: ${msg}`);
  }
}
