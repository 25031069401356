import Token from './Token.js';

/**
 * A token provider storing the token in browser local storage.
 */
export default class BrowserLocallyCachedAuthProvider {
  /* eslint-disable class-methods-use-this */
  /**
   * Returns the token currently held by the user.
   *
   * @returns {Promise<Token|void>}
   */
  async getToken(canFail) {
    const { token } = window.localStorage;
    if (!token && !canFail) return this.handleInvalid();
    const expiry = window.localStorage.tokenExpiry;
    return new Token(token, expiry);
  }

  /**
   * Handles the server rejecting the provider's token.
   *
   * @returns {Promise<void>}
   */
  async handleInvalid() {
    throw new Error('Expired or invalid token handling not implemented');
  }

  /**
   * Stores the token in the browser.
   *
   * @param token the token
   *
   * @returns {Promise<void>}
   */
  async setToken(token) {
    window.localStorage.token = token.token;
    window.localStorage.tokenExpiry = token.expiry;
  }
}
