import Vue from 'vue';
import Paginator from 'vuejs-paginate';
import VueModal from 'vue-js-modal';
import BootstrapVue from 'bootstrap-vue';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import moment from 'moment';
import AuTA from './model/AuTA.js';
import Severity from './model/Severity.js';
import App from './App.vue';
import router from './router.js';
import MetricEditor from './components/MetricEditor.vue';
import ScriptContainer from './components/ScriptContainer.vue';
import Fa from './components/Fa.vue';
import NoteView from './components/NoteView.vue';
import EntityView from './components/EntityView.vue';
import Loading from './components/Loading.vue';

Vue.config.productionTip = false;

Vue.component('paginate', Paginator);
Vue.component('MetricEditor', MetricEditor);
Vue.component('ScriptContainer', ScriptContainer);
Vue.component('Fa', Fa);
Vue.component('NoteView', NoteView);
Vue.component('EntityView', EntityView);
Vue.component('Loading', Loading);
Vue.use(VueModal);
Vue.use(BootstrapVue);
Vue.use(VuejsDialog);

const modalAuthProvider = AuTA.BrowserLocallyCachedAuthProvider();
modalAuthProvider.handleInvalid = async function handleInvalidAuthToken() {
  return new Promise((resolve, reject) => {
    if (!modalAuthProvider.vue) return reject(new Error('No vue instance'));
    modalAuthProvider.vue.$modal.show('login');
    return undefined;
  });
};

const auta = AuTA.client({
  authProvider: modalAuthProvider,
});

auta.version = AuTA.version();
Vue.prototype.$auta = auta;
// eslint-disable-next-line no-undef
Vue.prototype.$version = VERSION;

Vue.prototype.$moment = moment;

// Never output "a few seconds ago"
moment.relativeTimeThreshold('ss', -1);

Vue.mixin({
  data() {
    return {
      navbar: [
        { title: 'Courses', link: '/courses' },
        { title: 'Users', link: '/users' },
        { title: 'Settings', link: '/settings' },
        { title: 'Workers', link: '/workers' },
      ],
      Severity,
    };
  },
});

Vue.mixin({
  data() {
    return {
      timeUnits: [
        'NANOSECONDS',
        'MICROSECONDS',
        'MILLISECONDS',
        'SECONDS',
        'MINUTES',
        'HOURS',
        'DAYS',
      ],
    };
  },
});

Vue.mixin({
  methods: {
    data() {
      return {
        title: 'Courses',
      };
    },
    /**
     * Capitalizes the first letter of a string, lowercases the rest.
     * @param string the string to capitalize
     * @returns {string}
     */
    capitalize(string) {
      if (!string) return undefined;
      const newString = string.toLowerCase();
      return newString.charAt(0).toUpperCase() + newString.slice(1);
    },
    /**
     * Converts the string to lowercase.
     *
     * @param string the string
     * @returns {void|undefined} the lowercased string, or undefined if the string was undefined
     */
    lowercase(string) {
      if (!string) return undefined;
      return string.toLowerCase();
    },
    /**
     * Converts a metric name to something more readable. For example, CYCLOMATIC_COMPLEXITY is
     * converted to Cyclomatic Complexity.
     * @param {String} name the name of the metric
     * @returns {String} the metric name
     */
    beautifyMetricName(name) {
      return name.split(/_/g)
        .map(s => s[0].toLocaleUpperCase() + s.substring(1).toLocaleLowerCase())
        .join(' ');
    },

    /**
     * Converts a role name ROLE_TA to Ta.
     * @param {string} role the role name
     * @returns {string}
     */
    convertRoleNames(role) {
      if (role === 'ROLE_TEACHER') {
        return 'Instructor';
      }
      let newRole;
      if (role.startsWith('ROLE_')) {
        // eslint-disable-next-line prefer-destructuring
        newRole = role.split('ROLE_')[1];
      } else {
        newRole = role;
      }
      return this.capitalize(newRole);
    },
    genIdPart() {
      return Math.floor(Math.random() * 0x7FFFFFFF).toString(36);
    },
    genRandomId(prefix) {
      const pfx = (prefix || '') + (prefix ? ':' : '');
      return `${pfx}${this.genIdPart()}-${this.genIdPart()}-${this.genIdPart()}-${this.genIdPart()}`;
    },
    /**
     * A helper function for flattening arrays in reductions.
     *
     * @param a the target array
     * @param r? the source array
     * @returns {any[]} the flattened array
     */
    flatten: (a, r) => (r ? a.concat(r) : a),
    getNotesBySeverity(entity, severity) {
      if (!entity || !entity.reports) {
        return [];
      }

      return entity.reports.map(r => r.getNotesBySeverity(severity)).reduce(this.flatten, []);
    },
    /**
     * Sets the title of the current page.
     *
     * @param title the title
     */
    setTitle(title) {
      this.title = title;
      document.title = `${title} - AuTA`;
    },
  },
});

const vm = new Vue({
  router,
  render: h => h(App),
});
Vue.config.errorHandler = (err, v) => {
  // eslint-disable-next-line no-console
  console.error(err);
  v.$emit('error', err);
};
modalAuthProvider.vue = vm;
vm.$mount('#app');
