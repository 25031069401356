<template>
  <div class="row">
    <div class="col-12 py-md-3">
      <div class="btn-toolbar justify-content-between" role="toolbar"
           aria-label="Courses operations">
        <router-link to="/courses/new">
          <button type="button" class="btn btn-primary">
            <i class="fas fa-plus"></i>
            New
          </button>
        </router-link>
        <div class="input-group">
          <label for="search" class="sr-only">Search</label>
          <input type="search" id="search" name="search" placeholder="Search" />
        </div>
      </div>

      <div id="courses">
        <table class="table">
          <tr>
            <th class="courseCode">Course code</th>
            <th>Course Title</th>
            <th class="action"><Fa ico="edit" /></th>
            <th class="action"><Fa ico="clone" /></th>
            <th class="action"><Fa ico="trash-alt" /></th>
          </tr>
          <tr v-for="course in courses" :key="course.id">
            <td class="courseCode">
              {{ course.courseCode }}
            </td>
            <td>
              <router-link :to="{ name: 'course', params: { cid: course.id } }">
                {{ `${course.name} (${course.year})`}}
              </router-link>
            </td>
            <td>
              <router-link
                :to="{ name: 'course-editor', params: { cid: course.id, action: 'edit' } }"
                class="action">
                <Fa ico="edit" />
              </router-link>
            </td>
            <td>
              <router-link
                :to="{ name: 'course-editor', params: { cid: course.id, action: 'clone' } }"
                class="action">
                <Fa ico="clone" />
              </router-link>
            </td>
            <td>
              <a class="action text-danger" href="javascript:void(0)"
                 @click.prevent="deleteCourse(course.id)">
                <Fa ico="trash-alt" />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'courses',
  data() {
    return {
      loaded: false,
      courses: [],
    };
  },
  mounted() {
    this.fetchCourses();
  },
  async beforeRouteUpdate(to, from, next) {
    await this.fetchCourses();
    next();
  },
  unmounted() {
    this.depopulate();
  },
  methods: {
    async fetchCourses() {
      this.loaded = false;
      this.courses = await this.$auta.getCourses();
      this.loaded = true;
    },
    depopulate() {
      this.loaded = false;
      this.courses = [];
    },
    deleteCourse(cid) {
      this.$auta.deleteCourse(cid);
      this.courses = this.courses.filter((c) => c.id !== cid);
    },
  },
};
</script>

<style scoped lang="scss">
th.action {
  width: 1em;
  color: #A0A0A0;
}

td a.action {
  color: rgb(34, 34, 34);
}

.courseCode {
  width: 12%;
}
</style>
