import Report from './Report.js';
import Note from './Note.js';
import Entity from './Entity.js';

/**
 * The results of a single unit test.
 */

export default class TestResults {
  /**
   * Parses a unit test result from an object.
   *
   * @param obj? the object to parse the results from
   *
   * @returns {TestResults|null}
   */
  static parse(obj) {
    if (!obj) return null;

    return new TestResults(obj.id, obj.error,
      new Entity(null, 'Fake entity', true, 'TEST', [], {},
        [new Report('Fake metric', obj.notes.notes.map(Note.parse), obj.grade)]));
  }

  /**
   * Creates a new unit test result.
   *
   * @param id the id of the unit test
   * @param error the error while running the script, if any
   * @param fakeEntity the fake entity containing the results of the script
   */
  constructor(id, error, fakeEntity) {
    this.id = id;
    this.error = error;
    this.fakeEntity = fakeEntity;
  }
}
