import Vue from 'vue';
import Router from 'vue-router';
import Assignments from './views/assignments.vue';
import Assignment from './views/assignment.vue';
import AssignmentEditor from './views/assignment-editor.vue';
import Settings from './views/settings.vue';
import SettingsRedirect from './views/settings-redirect.vue';
import SettingsGeneral from './views/settings/general.vue';
import SettingsIntegrations from './views/settings/integrations.vue';
import SettingsMongoDb from './views/settings/mongodb.vue';
import SettingsSecurity from './views/settings/security.vue';
import SettingsWorkers from './views/settings/workers.vue';
import Submission from './views/submission.vue';
import Users from './views/users.vue';
import Workers from './views/workers.vue';
import Courses from './views/courses.vue';
import Course from './views/course.vue';
import CourseEditor from './views/course-editor.vue';
import Benchmarking from './views/benchmarking.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'courses',
      component: Courses,
      alias: '/courses',
    },
    {
      path: '/assignments',
      name: 'assignments',
      component: Assignments,
    },
    {
      path: '/assignment/new',
      name: 'assignment-editor-new',
      component: AssignmentEditor,
    },
    {
      path: '/assignment/:aid',
      name: 'assignment',
      component: Assignment,
    },
    {
      path: '/assignment/:aid/submission/:sid',
      name: 'submission',
      component: Submission,
    },
    {
      path: '/assignment/:aid/submission/:sid/benchmarking',
      name: 'benchmarking',
      component: Benchmarking,
    },
    {
      path: '/assignment/:aid/:action',
      name: 'assignment-editor',
      component: AssignmentEditor,
    },
    {
      path: '/workers',
      name: 'workers',
      component: Workers,
    },
    {
      path: '/settings',
      component: SettingsRedirect,
    },
    {
      path: '/course/:cid',
      name: 'course',
      component: Course,
    },
    {
      path: '/course/:cid/:action',
      name: 'course-editor',
      component: CourseEditor,
    },
    {
      path: '/courses/:new',
      name: 'course-editor-new',
      component: CourseEditor,
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
    },
    {
      path: '/users/:user',
      name: 'user',
      redirect() {
        return { name: 'users' };
      },
    },
    {
      path: '/settings',
      component: Settings,
      children: [
        {
          path: 'general',
          name: 'settings/general',
          component: SettingsGeneral,
        },
        {
          path: 'workers',
          name: 'settings/workers',
          component: SettingsWorkers,
        },
        {
          path: 'security',
          name: 'settings/security',
          component: SettingsSecurity,
        },
        {
          path: 'integrations',
          name: 'settings/integrations',
          component: SettingsIntegrations,
        },
        {
          path: 'mongodb',
          name: 'settings/mongodb',
          component: SettingsMongoDb,
        },
      ],
    },
  ],
});
