<template>
  <div class="row">
    <div class="col-12">
      <nav role="navigation" class="setting-tabs">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <router-link :to="{ name: 'settings/general' }"
                         class="nav-link" active-class="active">
              General
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'settings/workers' }"
                         class="nav-link" active-class="active">
              Workers
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'settings/security' }"
                         class="nav-link" active-class="active">
              Security
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'settings/integrations' }"
                         class="nav-link" active-class="active">
              Integrations
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'settings/mongodb' }"
                          class="nav-link" active-class="active">
              MongoDB
            </router-link>
          </li>
        </ul>
      </nav>
      <router-view></router-view>
      <div class="row justify-content-between">
        <div class="col-md-3">
          <button class="btn btn-default" @click="reset">Reset</button>
        </div>
        <div class="col-md-3 d-flex justify-content-end">
          <button class="btn btn-primary" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'settings',
  data() {
    return {
      settings: {},
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    async reset() {
      this.settings = await this.$auta.getSettings();
    },
    async save() {
      await this.$auta.setSettings(this.settings);
    },
  },
};
</script>

<style scoped lang="less">
.nav-link {
  color: #828282;
}

.setting-tabs {
  margin-bottom: 1em;
}
</style>
