/**
 * A note in a report.
 */
export default class Note {
  /**
   * Parses a note from an object.
   *
   * @param obj? the object to parse the note from
   *
   * @returns {Note|null}
   */
  static parse(obj) {
    if (!obj) return null;

    return new Note(obj.severity, obj.message);
  }

  /**
   * Creates a new note.
   *
   * @param severity the severity of the note
   * @param message the message of the note
   */
  constructor(severity, message) {
    this.severity = severity;
    this.message = message;
  }
}
